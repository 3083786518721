import React, { ChangeEventHandler, FormEventHandler } from 'react'
import { RouteComponentProps } from 'react-router'
import swal from 'sweetalert2'
import Config from '../../config'
import {
  IAccountReportsResponse,
  IMediaUploadThumbnailResponse,
  IPostPublicationBody,
  IPostPublicationResponse,
} from '../../interface.api'
import { Publication } from '../../interfaces.ui'
import Helper from '../../utilis/Helpers'
import { ReportUi } from './Report.ui'

export default class Report extends React.Component<
  RouteComponentProps<{ id: string }> & {},
  {
    kid?: IAccountReportsResponse
    showModal: boolean
    redirect: boolean
    publication: null | FormData
    pubDescription: string
    isLoading: boolean
    fileName?: string
  }
> {
  constructor(props: RouteComponentProps<{ id: string }> & {}) {
    super(props)

    this.state = {
      showModal: false,
      redirect: false,
      publication: null,
      pubDescription: '',
      isLoading: true,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    const me = Helper.getStoredProfile()
    if (!me) return
    let url = `/accounts/${me._id}/reports/${this.props.match.params.id}`
    var that = this
    Helper.ApiRequest<IAccountReportsResponse>(url)
      .then(function (kid) {
        if (typeof kid !== 'object' || 'error' in kid) {
          return Promise.reject()
        }
        that.setState({ kid, isLoading: false })
      })
      .then(function () {
        Helper.accessLog(null, null, 'access child report', true)
      })
  }

  edit = () => {
    this.setState({ showModal: true })
  }

  delete = () => {
    if (!this.state.kid) return

    const { id, displayName } = this.state.kid
    swal({
      title: ' المرجو التأكيد ',
      text: ' أنت على وشك حذف الطفل "' + displayName + '"',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'حذف',
      cancelButtonText: 'إلغاء',
    }).then((result) => {
      if (result.value === true) {
        let url = '/kids/' + id
        var that = this
        that.setState({ isLoading: true })
        Helper.ApiRequest(url, { method: 'DELETE' })
          .then(function (kid) {
            swal({
              title: '',
              text: 'لقد تم الحذف بنجاح',
              type: 'success',
              confirmButtonText: 'إخفاء',
            }).then((result) => {
              that.setState({ redirect: true, isLoading: false })
            })
          })
          .then(function () {
            Helper.accessLog(null, null, 'delete child', true)
          })
      }
    })
  }

  closeForm = () => {
    this.setState({ showModal: false })
  }

  fileInfo: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files?.[0]) {
      const fileData = e.target.files[0]
      const fileName = fileData.name

      const file = new FormData()
      file.append('upload', fileData)
      this.setState({ fileName, publication: file })
    } else {
      this.setState({ publication: null })
    }
  }

  handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    let that = this
    switch (event.target.name) {
      case 'pubDescription':
        that.setState({ pubDescription: event.target.value })
        break
      default:
        break
    }
  }

  submitPublication: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()
    if (!this.state.kid) return
    if (!this.state.publication) return
    var that = this

    const kid = this.state.kid
    this.setState({ isLoading: true })
    // TODO: Use helper for upload url
    Helper.ApiRequest<IMediaUploadThumbnailResponse>(
      '/media/upload/thumbnail',
      {
        method: 'POST',
        body: that.state.publication,
      },
      true,
      true
    )
      .then((info) => {
        if (typeof info !== 'object' || 'error' in info) {
          return Promise.reject()
        }
        const data: IPostPublicationBody = {
          kid: kid.id,
          thumbnail: info.thumbnail,
          description: that.state.pubDescription,
          content: info.id,
        }
        Helper.ApiRequest<IPostPublicationResponse>(
          `/paths/${kid.path}/publications`,
          {
            method: 'POST',
            body: JSON.stringify(data),
          }
        )
          .then((d) => {
            if (typeof d !== 'object' || 'error' in d) {
              return Promise.reject()
            }
            const publication: Publication = {
              _id: d._id,
              date: d.date,
              kid: kid.id,
              thumbnail: info.thumbnail,
              description: that.state.pubDescription,
              content: info.id,
            }

            if (!kid.publications) {
              kid.publications = [publication]
            } else {
              kid.publications.push(publication)
            }
            that.setState({
              kid: kid,
              publication: null,
              pubDescription: '',
              isLoading: false,
            })
          })
          .then(function () {
            Helper.accessLog(null, null, 'upload publication', true)
            swal({
              title: '',
              text: 'لقد تم الرفع بنجاح, ستكون الصورة متوفرة على المنصة في غضون 24 ساعة على الأكثر ',
              type: 'success',
              confirmButtonText: 'إخفاء',
            })
          })
      })
      .catch((err) => {
        that.setState({ isLoading: false })
        swal({
          title: '',
          text: err.message,
          type: 'error',
          confirmButtonText: 'إخفاء',
        })
      })
  }

  kidsLoging = () => {
    if (!this.state.kid) return
    const kid = this.state.kid
    let username = kid.username
    let token = kid.authToken
    let url = `${Config.kidsURL}/login/${username}/${token}`

    window.open(url, '_blank')
  }

  deletePublication = (publication: Publication) => {
    let imageData = Helper.MediaURL(publication.thumbnail)

    swal({
      title: ' المرجو التأكيد ',
      text: '',
      type: 'warning',
      html:
        '<div> أنت على وشك حذف المشاركة </div><img class="mx-auto" src=' +
        imageData +
        ' />',
      showCancelButton: true,
      confirmButtonText: 'حذف',
      cancelButtonText: 'إلغاء',
    }).then((result) => {
      if (result.dismiss === swal.DismissReason.cancel) return

      if (result.value === true) {
        let url = '/publications/' + publication._id
        var that = this
        that.setState({ isLoading: true }) // show loading screen
        Helper.ApiRequest(url, { method: 'DELETE' })
          .then(function (publication) {
            swal({
              title: '',
              text: 'لقد تم الحذف بنجاح',
              type: 'success',
              confirmButtonText: 'إخفاء',
            })
            that.setState({ isLoading: false }) // hide loading screen
            that.getData()
          })
          .then(function () {
            Helper.accessLog(null, null, 'delete publication', true)
          })
          .catch(function (err) {
            swal({
              title: '',
              text: err.message,
              type: 'error',
              confirmButtonText: 'إخفاء',
            })
          })
      }
    })
  }

  render() {
    return (
      <ReportUi
        isLoading={this.state.isLoading}
        onChangeFile={this.fileInfo}
        onChangePublicationDescription={this.handleChange}
        onCloseForm={this.closeForm}
        onDelete={this.delete}
        onDeletePublication={this.deletePublication}
        onEdit={this.edit}
        onKidLogin={this.kidsLoging}
        onRefresh={this.getData}
        onSubmitPublication={this.submitPublication}
        pubDescription={this.state.pubDescription}
        publication={this.state.publication}
        redirect={this.state.redirect}
        showModal={this.state.showModal}
        fileName={this.state.fileName}
        kid={this.state.kid}
      />
    )
  }
}
