import React, { VFC } from 'react'
import { Link } from 'react-router-dom'

export const ButtonBlue: VFC<{
  icon?: string
  label?: string
  disabled?: boolean
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset'
  fullWidth?: boolean
}> = (props) => {
  return (
    <button
      className={`${
        props.fullWidth ? 'w-full' : ''
      } bg-[#368ed6] shadow-md shadow-gray-500 rounded text-white px-4 py-2 font-bold flex items-center justify-center cursor-pointer disabled:bg-opacity-60 disabled:text-gray-500`}
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.type}
    >
      {props.icon && <i className={props.icon}></i>}
      {props.label && (
        <span className={`text-center ${props.icon ? 'mr-2' : ''}`}>
          {props.label}
        </span>
      )}
    </button>
  )
}

export const ButtonUnderlined: VFC<{
  label?: string
  disabled?: boolean
  onClick: () => void
  type?: 'button' | 'submit' | 'reset'
}> = (props) => {
  return (
    <button
      className="underline"
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.type}
    >
      {props.label && <span>{props.label}</span>}
    </button>
  )
}

export const LinkBlue: VFC<{
  to: string
  icon?: string
  label?: string
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}> = (props) => {
  return (
    <Link
      to={props.to}
      className="bg-[#368ed6] shadow-md shadow-gray-500 rounded text-white px-4 py-2 font-bold flex items-center justify-center cursor-pointer disabled:bg-opacity-60 disabled:text-gray-500"
      onClick={props.onClick}
    >
      {props.icon && <i className={props.icon}></i>}
      {props.label && (
        <span className={`text-center ${props.icon ? 'mr-2' : ''}`}>
          {props.label}
        </span>
      )}
    </Link>
  )
}
