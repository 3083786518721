import React from 'react'
import { deviceDetect } from 'react-device-detect'
import { Redirect } from 'react-router'
import swal from 'sweetalert2'
import { IMediaUploadResponse } from '../interface.api'
import Helper from '../utilis/Helpers'
import { ButtonBlue, ButtonUnderlined } from './buttons/Buttons'
import { AppProgressBar } from './layout/AppProgressBar'
import { FieldGroup2 } from './Widgets'

interface Props {
  toggle?: () => void
  refresh?: () => void
  isReply?: boolean
  to?: string
  subject?: string
  id?: string
}
export class EmailForm extends React.Component<
  Props,
  {
    subject: string
    body: string
    file: FormData | null
    fileName?: string
    sent: boolean
    cancel?: boolean
    isLoading: boolean
  }
> {
  constructor(props: Props) {
    super(props)

    this.state = {
      subject: '',
      body: '',
      file: null,
      sent: false,
      isLoading: true,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    this.setState({ isLoading: false })
  }

  handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    switch (event.target.name) {
      case 'subject':
        this.setState({ subject: event.target.value })
        break
      case 'body':
        this.setState({ body: event.target.value })
        break
      case 'file':
        const element = event.target
        let file = new FormData()

        let fileData = element.files?.[0]
        if (fileData) {
          file.append('upload', fileData)
          this.setState({ file: file, fileName: fileData.name })
        }

        break
      default:
        break
    }
  }

  afterSubmit(d: { error: true; message: string } | {}) {
    this.setState({ isLoading: false })
    if (typeof d === 'object' && 'error' in d && d.error) {
      swal({
        title: '',
        text: d.message,
        type: 'error',
        confirmButtonText: 'إخفاء',
      })
    } else {
      swal({
        title: '',
        text: 'لقد تم الإرسال بنجاح',
        type: 'success',
        confirmButtonText: 'إخفاء',
      }).then((res) => {
        if (this.props.toggle) this.props.toggle()
        if (this.props.refresh) this.props.refresh()
        else this.setState({ sent: true })
      })
      Helper.accessLog(null, null, 'send email', true)
    }
  }

  handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()
    const me = Helper.getStoredProfile()
    if (!me) return
    let url = '/emails'
    let uploadUrl = '/media/upload'
    var that = this

    that.setState({ isLoading: true })
    if (!that.state.file) {
      let data = {
        ticket: that.props.id,
        to: that.props.to,
        from: me._id,
        subject: that.props.subject ? that.props.subject : that.state.subject,
        body: that.state.body,
        device: deviceDetect(),
      }
      Helper.ApiRequest(url, {
        method: 'POST',
        body: JSON.stringify(data),
      })
        .then((d) => {
          that.afterSubmit(d)
        })
        .catch((err) => {
          that.setState({ isLoading: false })
          swal({
            title: '',
            text: err,
            type: 'error',
            confirmButtonText: 'إخفاء',
          })
        })
    } else {
      Helper.ApiRequest<IMediaUploadResponse>(
        uploadUrl,
        {
          method: 'POST',
          body: that.state.file,
        },
        true,
        true
      )
        .then((info) => {
          if (info === true) {
            return Promise.reject()
          }
          if ('error' in info) {
            return Promise.reject()
          }

          let data = {
            ticket: that.props.id,
            from: me._id,
            to: that.props.to,
            subject: that.props.subject
              ? that.props.subject
              : that.state.subject,
            body: that.state.body,
            attachment: info.id,
            device: deviceDetect(),
          }

          Helper.ApiRequest(url, {
            method: 'POST',
            body: JSON.stringify(data),
          }).then((d) => {
            that.afterSubmit(d)
          })
        })
        .catch((err) => {
          that.setState({ isLoading: false })
          swal({
            title: '',
            text: err,
            type: 'error',
            confirmButtonText: 'إخفاء',
          })
        })
    }
  }

  cancel = () => {
    this.setState({ cancel: true })
  }

  render() {
    if (this.state.sent) return <Redirect push to="/tickets" />
    if (this.state.cancel) return <Redirect to={'/tickets'} />
    return (
      <>
        {this.state.isLoading && <AppProgressBar />}

        <form onSubmit={this.handleSubmit}>
          <div className="flex flex-col max-w-2xl mx-auto">
            {!this.props.isReply && (
              <>
                <div className="text-[#34495e] text-xl font-bold text-center">
                  إنشاء رسالة
                </div>

                <div className="mb-2">
                  <FieldGroup2
                    name="subject"
                    type="text"
                    label="عنوان الرسالة"
                    value={this.state.subject}
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </>
            )}

            <div className="mb-2">
              <FieldGroup2
                name="body"
                rows={10}
                label="تفاصيل الرسالة"
                value={this.state.body}
                onChange={this.handleChange}
                required
              />
            </div>
            <div>
              <div className="font-bold mb-1">أرفق ملفًّا</div>
              <div className="border border-[#34495e] rounded p-1">
                <label
                  tabIndex={0}
                  className="bg-[#d9534f] text-white rounded text-center px-4 py-2 mb-0 ml-4 font-normal inline-flex justify-center items-center cursor-pointer"
                >
                  <span className="">{'اختر الملف'}</span>
                  <input
                    name="file"
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={this.handleChange}
                  />
                </label>
                <span>{this.state.fileName || 'لا يوجد ملف'}</span>
              </div>
            </div>
            <div className="flex mt-8">
              <div className="ml-4">
                <ButtonBlue
                  fullWidth
                  type="submit"
                  icon="fa fa-send"
                  label="إرسال"
                />
              </div>

              {!this.props.isReply && (
                <ButtonUnderlined
                  type="button"
                  onClick={this.cancel}
                  label="إلغاء"
                />
              )}
            </div>

            <p className="mt-4">
              من أجل خدمتكم بشكل أفضل وحل مشكلتكم بشكل صحيح، سنقوم بجمع معلومات
              عن الجهاز المستخدم من طرفكم عند ارسالكم للطلب
            </p>
          </div>
        </form>
      </>
    )
  }
}
