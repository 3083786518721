import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'
import { ILoginResponse, IProfile } from '../interface.api'
import { FetchError, isFetchError } from '../utilis/FetchError'

import Helper from '../utilis/Helpers'

export interface LoginProps {
  email: string
  password: string
}

export interface IProfileContext {
  profile: IProfile | null | undefined
  logout: () => void
  login: (props: LoginProps) => Promise<IProfile | string | null>
}

export const ProfileContext = createContext<IProfileContext>({
  profile: undefined,
  logout: () => null,
  login: async () => null,
})

export const ProfileProvider: FC = (props) => {
  const history = useHistory()

  const storedProfile: IProfile | undefined = useMemo(() => {
    return Helper.getStoredProfile()
  }, [])

  const {
    data: profile,
    mutate,
    error,
  } = useSWR<IProfile | null, FetchError>('/me', {
    fallbackData: storedProfile,
    shouldRetryOnError: false,
    onSuccess(data, key, config) {
      if (data) Helper.setStoredProfile(data)
    },
  })

  const login = useCallback(
    async (props: LoginProps): Promise<IProfile | string | null> => {
      try {
        const response = await Helper.ApiRequest<ILoginResponse, string>(
          '/auth/login',
          {
            method: 'POST',
            body: JSON.stringify(props),
          }
        )

        if (typeof response !== 'object') {
          return null
        }

        if ('error' in response) {
          return response.message
        }

        await Helper.getAndSetEncryptionKey(response.token)

        Helper.setJwtToken(response.token)

        const profile = await mutate()

        await Helper.accessLog(null, null, 'login', true)

        return profile || null
      } catch (err) {
        if (isFetchError(err)) {
          return err.message
        }

        return JSON.stringify(err)
      }
    },
    [mutate]
  )

  const logout = useCallback(async () => {
    Helper.logOut()
    await mutate(null, false)
    history.replace('/login')
  }, [mutate, history])

  const value = useMemo(() => {
    return { profile: error ? undefined : profile, logout, login }
  }, [profile, logout, login, error])

  return (
    <ProfileContext.Provider value={value}>
      {props.children}
    </ProfileContext.Provider>
  )
}

export function useProfile(): IProfileContext {
  return useContext(ProfileContext)
}
