import React, { useState, VFC } from 'react'
import ReactDOM from 'react-dom'

import '../../stylesheets/progress-bar.css'

export const AppProgressBar: VFC = React.memo(() => {
  const [progressBarRoot] = useState(() => {
    return document.getElementById('progress-bar-root')
  })

  if (!progressBarRoot) return null

  return ReactDOM.createPortal(
    <div className="fixed w-full h-2 z-[99999] bg-gray-800">
      <div className="h-full shim-green"></div>
    </div>,
    progressBarRoot
  )
})
