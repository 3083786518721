import React, { VFC } from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import { useProfile } from './contexts/ProfileContext'
import Activation from './pages/activation/Activation.page'
import ForgotPassword from './pages/forgot-password/ForgotPassword.page'
import Home from './pages/home/Home.page'
import { Login } from './pages/login/Login.page'
import { NewTicket } from './pages/new-ticket/NewTicket.page'
import { NotFound } from './pages/not-found/NotFound.page'
import Packages from './pages/packages/Packages.page'
import Posts from './pages/posts/Posts.page'
import Profile from './pages/profile/Profile.page'
import Reports from './pages/report/Report.page'
import ResetPassword from './pages/reset-password/ResetPassword.page'
import { Ticket } from './pages/ticket/Ticket.page'
import { Tickets } from './pages/tickets/Tickets.page'
import ToPayment from './pages/topayment/ToPayment.page'

const Routes: VFC = () => (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Route path="/forgotpassword" component={ForgotPassword} />
    <Route
      path="/reset/:id(.+)/:token(.+)/:isNew(0|1)"
      component={ResetPassword}
    />
    <Route path="/activate/:id(.+)/:code(.+)" component={Activation} />
    <PrivateRoute exact path="/" component={Home} />
    <PrivateRoute exact path="/tickets" component={Tickets} />
    <PrivateRoute exact path="/tickets/new" component={NewTicket} />
    <PrivateRoute path="/tickets/:id(.+)" component={Ticket} />
    <PrivateRoute exact path="/report/:id(.+)" component={Reports} />
    <PrivateRoute exact path="/profile" component={Profile} />
    <PrivateRoute exact path="/posts" component={Posts} />
    <PrivateRoute exact path="/packages" component={Packages} />
    <PrivateRoute exact path="/topayment/:duration(.+)" component={ToPayment} />
    <Route component={NotFound} />
  </Switch>
)

const PrivateRoute: VFC<RouteProps> = ({ component: Component, ...rest }) => {
  const { profile } = useProfile()

  return (
    <Route
      {...rest}
      render={(props) => {
        return Component && profile ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
            push={true}
          />
        )
      }}
    />
  )
}

export default Routes
