import moment from 'moment'
import React, { useEffect, useState, VFC } from 'react'

import Helper from '../../utilis/Helpers'

import { ToPaymentUi } from './ToPayment.ui'
import { useParams } from 'react-router-dom'
import { IPaymentsDetailsApi } from '../../interface.api'
import { useProfile } from '../../contexts/ProfileContext'

const ToPayment: VFC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [showPromo, setShowPromo] = useState(false)

  const [paymentDetails, setPaymentDetails] = useState<IPaymentsDetailsApi>()

  const [expirationDate, setExpirationDate] = useState(() => moment())

  const { duration } = useParams<{ duration: string }>()

  const { profile } = useProfile()

  useEffect(() => {
    Helper.ApiRequest<IPaymentsDetailsApi>('/payments/details').then(
      (pConf) => {
        if (typeof pConf === 'boolean') return
        if ('error' in pConf) return
        if (!profile) return

        if (pConf.packages.indexOf(duration) === -1) return

        setPaymentDetails(pConf)

        let expirationDate = moment(profile.expires)
        let today = moment()
        let showPromo = false
        if (today.isAfter(expirationDate))
          expirationDate = today.hour(0).minute(0).second(0).millisecond(0)

        if (duration === 'yearly') {
          expirationDate.add(1, 'years')
        } else {
          expirationDate.add(3, 'months')
        }

        if (!profile.expires && pConf.promotion)
          // if promotion add it to the date @TODO: this is a hot fix for launch, needs to be reviewed
          expirationDate.add(pConf.promotion, pConf.promotionUnit as any)

        if (!profile.expires) showPromo = true

        setExpirationDate(expirationDate)
        setShowPromo(showPromo)
        setIsLoading(false)
      }
    )
  }, [profile, duration])

  return (
    <ToPaymentUi
      amount={paymentDetails?.amount}
      currency={paymentDetails?.currency || '$'}
      currencyCode={paymentDetails?.currencyCode || 'usd'}
      duration={duration}
      expirationDate={expirationDate}
      isLoading={isLoading}
      packages={paymentDetails?.packages || []}
      promotionLabel={paymentDetails?.promotionLabel || ''}
      showPromo={showPromo}
    />
  )
}

export default ToPayment
