import AES from 'crypto-js/aes'
import EncoderUtf8 from 'crypto-js/enc-utf8'
import Config from '../config'

type EncryptedStorageKey =
  | typeof Config.localStorage.tokenIndex
  | typeof Config.localStorage.whoamiIndex

type EncryptedStorage = Partial<Record<EncryptedStorageKey, string>>

export class EncryptedLocalStorage {
  public static get(key: EncryptedStorageKey): string | null {
    let storage = localStorage.getItem(Config.localStorage.keyName)
    if (storage == null) return null
    const splitted = EncryptedLocalStorage.splitStorageAndKey(storage)

    if (splitted.storage === '') return null

    const decrypted = EncryptedLocalStorage.decryptStorage(splitted)

    return decrypted?.[key] || null
  }

  public static set(key: EncryptedStorageKey, value: string): void {
    let storage = localStorage.getItem(Config.localStorage.keyName)
    if (storage == null) return

    const splitted = EncryptedLocalStorage.splitStorageAndKey(storage)

    const decrypted: EncryptedStorage = splitted.storage
      ? EncryptedLocalStorage.decryptStorage(splitted) || {}
      : {}

    decrypted[key] = value

    const encryptedValue = EncryptedLocalStorage.encryptStorage({
      key: splitted.key,
      storage: decrypted,
    })

    localStorage.setItem(Config.localStorage.keyName, encryptedValue)
  }

  public static getKey(): string | null {
    let storage = localStorage.getItem(Config.localStorage.keyName)
    if (storage == null) return null

    const splitted = EncryptedLocalStorage.splitStorageAndKey(storage)

    return splitted.key
  }

  public static setKey(encryptionKey: string): void {
    localStorage.setItem(Config.localStorage.keyName, encryptionKey)
  }

  public static clear(): void {
    localStorage.removeItem(Config.localStorage.keyName)
  }

  private static splitStorageAndKey(storageValue: string): {
    key: string
    storage: string
  } {
    const index = storageValue.length - Config.localStorage.keyLength

    const encryptionKey = storageValue.substring(index)
    const storage = storageValue.substring(0, index)

    return {
      key: encryptionKey,
      storage,
    }
  }

  private static decryptStorage(props: {
    key: string
    storage: string
  }): EncryptedStorage | null {
    try {
      const bytes = AES.decrypt(props.storage, props.key)
      return JSON.parse(bytes.toString(EncoderUtf8)) as EncryptedStorage
    } catch (error) {
      console.error(error)
      //fail to parse or decrypt, reset everything
      return null
    }
  }

  private static encryptStorage(props: {
    key: string
    storage: EncryptedStorage
  }): string {
    try {
      let encryptedValue = AES.encrypt(
        JSON.stringify(props.storage),
        props.key
      ).toString()

      encryptedValue += props.key
      return encryptedValue
    } catch (error) {
      console.error(error)
      return ''
    }
  }
}
