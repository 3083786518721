import { VFC } from 'react'

export const ButtonKidLogin: VFC<{ onClick: () => void }> = (props) => {
  return (
    <button
      className="mt-auto w-full justify-center bg-[#34495e] shadow-md shadow-gray-500 rounded text-white px-4 py-2 font-bold flex items-center"
      onClick={props.onClick}
    >
      <i className="fa fa-globe" aria-hidden="true"></i>
      &nbsp;المنصة
    </button>
  )
}
