import moment from 'moment'
import React, { ChangeEventHandler, FC, VFC } from 'react'
import { Link } from 'react-router-dom'
import { ButtonBlue, ButtonUnderlined } from '../../components/buttons/Buttons'
import { AppProgressBar } from '../../components/layout/AppProgressBar'
import { FieldGroup2, Select } from '../../components/Widgets'
import Unsubscribe from '../../containers/Unsubscribe'
import { CountriesSelectorAr } from '../../utilis/CountriesSelectorAr'

interface Errors {
  firstName?: string
  cfNewPassword?: string
  currentPassword?: string
  newPassword?: string
}
interface State {
  tab: number
  year: string
  firstName: string
  middleName: string
  lastName: string
  country: string
  region: string
  email: string
  currentPassword: string
  newPassword: string
  cfNewPassword: string
  passwordVerification: string[]
  errors: Errors
  isLoading: boolean
  showModal: boolean
  status: string
  expires: string

  onSelectTab: (tab: number) => void

  onCloseForm: () => void
  onUnsubscribe: () => void
  onChangePassword: React.FormEventHandler<HTMLFormElement>

  onChangeFirstName: ChangeEventHandler<HTMLInputElement>
  onChangeMiddleName: ChangeEventHandler<HTMLInputElement>
  onChangeLastName: ChangeEventHandler<HTMLInputElement>
  onChangeCountry: React.ChangeEventHandler<HTMLSelectElement>
  onChangeRegion: ChangeEventHandler<HTMLInputElement>
  onChangeCurrentPassword: ChangeEventHandler<HTMLInputElement>
  onChangeCfNewPassword: ChangeEventHandler<HTMLInputElement>
  onChangeYear: ChangeEventHandler<HTMLSelectElement>
  onChangeNewPassword: ChangeEventHandler<HTMLInputElement>

  onSubmit: React.FormEventHandler<HTMLFormElement>
}
export const ProfileUi: VFC<State> = (props) => {
  const yearsOptions: number[] = []
  const thisYear = new Date().getFullYear()
  for (let y = thisYear; y >= thisYear - 100; y--) {
    yearsOptions.push(y)
  }

  return (
    <>
      {props.isLoading && <AppProgressBar />}
      <div id="profile-page">
        <div className="w-full max-w-2xl mx-auto">
          <ul role="tablist" className="flex mb-[-1px]">
            <TabButton
              isActive={props.tab === 1}
              label="الملف الشخصي"
              onClick={props.onSelectTab}
              tabNumber={1}
            />
            <TabButton
              isActive={props.tab === 2}
              label="كلمة المرور"
              onClick={props.onSelectTab}
              tabNumber={2}
            />
            <TabButton
              isActive={props.tab === 3}
              label="معلومات الدفع"
              onClick={props.onSelectTab}
              tabNumber={3}
            />
          </ul>
          <TabContent isActive={props.tab === 1}>
            <form onSubmit={props.onSubmit}>
              <div className="flex flex-col items-center sm:items-start sm:flex-row">
                <div className="min-w-[64px] w-16 h-16 border-2 border-[#d6d6d6] border-solid rounded-full flex items-center justify-center">
                  <i
                    className="fa fa-user text-4xl text-[#3686d0]"
                    aria-hidden="true"
                  ></i>
                </div>

                <div className="flex flex-col sm:pr-4 w-full">
                  <div className="mb-2">
                    <FieldGroup2
                      name="firstName"
                      type="text"
                      label="الاسم الشخصي"
                      value={props.firstName}
                      onChange={props.onChangeFirstName}
                      error={props.errors.firstName}
                    />
                  </div>
                  <div className="mb-2">
                    <FieldGroup2
                      name="middleName"
                      type="text"
                      label="الاسم الأوسط"
                      value={props.middleName}
                      onChange={props.onChangeMiddleName}
                    />
                  </div>

                  <div className="mb-2">
                    <FieldGroup2
                      name="lastName"
                      type="text"
                      label="الاسم العائلي"
                      value={props.lastName}
                      onChange={props.onChangeLastName}
                    />
                  </div>
                  <div className="mb-2">
                    <FieldGroup2
                      name="email"
                      type="text"
                      label="البريد الإلكتروني"
                      value={props.email}
                      disabled
                    />
                  </div>
                  <div className="mb-2">
                    <label className="w-full">
                      <div className="font-bold mb-1">البلد</div>

                      <CountriesSelectorAr
                        value={props.country}
                        name="country"
                        onChange={props.onChangeCountry}
                      />
                    </label>
                  </div>
                  <div className="mb-2">
                    <FieldGroup2
                      name="region"
                      type="text"
                      label="المدينة / الجهة"
                      value={props.region}
                      onChange={props.onChangeRegion}
                    />
                  </div>
                  <div className="mb-6">
                    <label className="w-full">
                      <div className="mb-1 font-bold">تاريخ الميلاد</div>
                      <Select
                        name="year"
                        value={props.year}
                        onChange={props.onChangeYear}
                      >
                        <option value="" disabled>
                          السنة
                        </option>
                        {yearsOptions.map(function (val, key) {
                          return (
                            <option key={key} value={val}>
                              {val}
                            </option>
                          )
                        })}
                      </Select>
                    </label>
                  </div>

                  <ButtonBlue type="submit" label="حفظ" />

                  <div className="mt-6">
                    <ButtonUnderlined
                      onClick={props.onUnsubscribe}
                      label="إغلاق الحساب"
                      type="button"
                    />
                  </div>
                </div>
              </div>
            </form>
          </TabContent>

          <TabContent isActive={props.tab === 2}>
            <form onSubmit={props.onChangePassword}>
              <div className="flex flex-col">
                <div className="mb-2">
                  <FieldGroup2
                    name="currentPassword"
                    type="password"
                    label="كلمة المرور الحالية"
                    placeholder=""
                    value={props.currentPassword}
                    onChange={props.onChangeCurrentPassword}
                    error={props.errors.currentPassword}
                  />
                </div>
                <div className="mb-2">
                  <FieldGroup2
                    name="newPassword"
                    type="password"
                    label="كلمة المرور الجديدة"
                    placeholder=""
                    value={props.newPassword}
                    onChange={props.onChangeNewPassword}
                    error={
                      props.passwordVerification?.length
                        ? props.passwordVerification
                        : props.errors.newPassword
                    }
                  />
                </div>
                <div className="mb-6">
                  <FieldGroup2
                    name="cfNewPassword"
                    type="password"
                    label="تأكيد كلمة المرور الجديدة"
                    placeholder=""
                    value={props.cfNewPassword}
                    onChange={props.onChangeCfNewPassword}
                    error={props.errors.cfNewPassword}
                  />
                </div>

                <ButtonBlue
                  type="submit"
                  label="حفظ"
                  disabled={
                    !props.currentPassword ||
                    !props.newPassword ||
                    !props.cfNewPassword ||
                    !!props.passwordVerification.length ||
                    !!props.errors.newPassword ||
                    !!props.errors.newPassword ||
                    !!props.errors.cfNewPassword
                  }
                />
              </div>
            </form>
          </TabContent>

          <TabContent isActive={props.tab === 3}>
            <div className="mb-4 flex flex-wrap">
              <label className="text-[#4a4a4a]">
                الوضعية الاعتمادية للحساب :
              </label>

              <div className="underline underline-offset-1 font-bold mr-4">
                {props.status === 'active' ? 'مفعلة' : ' غير مفعلة'}
              </div>
            </div>
            {props.expires && (
              <div className="mb-4 flex flex-wrap">
                <label className="text-[#4a4a4a]">
                  اشتراككم صالح إلى غاية :
                </label>

                <div className="underline font-bold mr-4">
                  {moment.utc(props.expires).format('YYYY-MM-DD')}
                </div>
              </div>
            )}
            {props.expires && (
              <div className="flex items-center justify-center py-5">
                <Link
                  to={`/packages`}
                  className="bg-green-600 py-2 px-4 text-white rounded"
                >
                  <i className="fa fa-credit-card"></i>
                  <span className="mr-2">فعّل اشتراكك الآن</span>
                </Link>
              </div>
            )}
          </TabContent>
        </div>
      </div>
      {props.showModal && <Unsubscribe close={props.onCloseForm} />}
    </>
  )
}

const TabButton: VFC<{
  onClick: (tab: number) => void
  tabNumber: number
  isActive: boolean
  label: string
}> = (props) => {
  return (
    <li role="presentation" className={``}>
      <button
        role="tab"
        className={`ml-1 font-bold py-3 px-2 sm:px-8  border border-b-0 rounded-t-xl ${
          props.isActive ? 'bg-white' : 'bg-[#34495e] text-white'
        }`}
        onClick={() => props.onClick(props.tabNumber)}
        aria-selected={props.isActive ? 'true' : 'false'}
      >
        {props.label}
      </button>
    </li>
  )
}

const TabContent: FC<{
  isActive: boolean
}> = (props) => {
  return (
    <div
      role="tabpanel"
      aria-hidden={props.isActive ? 'false' : 'true'}
      className={`bg-white border rounded-xl rounded-tr-none p-4 ${
        props.isActive ? '' : 'hidden'
      }`}
    >
      {props.children}
    </div>
  )
}
