import React, { FC, useCallback, VFC } from 'react'
import { useHistory } from 'react-router'
import { LinkBlue } from '../../components/buttons/Buttons'
import { AppProgressBar } from '../../components/layout/AppProgressBar'
import { FieldGroup2 } from '../../components/Widgets'
import { ITicket } from '../../interfaces.ui'
import Helper from '../../utilis/Helpers'

export const TicketsUi: VFC<{
  unread: number
  profileId: string
  tickets: null | ITicket[]
  isLoading: boolean
  search: string
  onSearch: React.ChangeEventHandler<HTMLInputElement>
}> = (props) => {
  return (
    <>
      <div className="flex justify-between mb-4">
        <h3 className="text-2xl">
          <UnreadBadge unread={props.unread} /> البريد
        </h3>
        <div className="flex items-center justify-center">
          <div className="whitespace-nowrap">
            <LinkBlue
              to="/tickets/new"
              label="إنشاء رسالة"
              icon="fa fa-paper-plane"
            />
          </div>
        </div>
      </div>
      <div className="max-w-md mb-4">
        <FieldGroup2
          type="text"
          value={props.search}
          onChange={props.onSearch}
          placeholder="بحث..."
          addOn={<i className="fa fa-search"></i>}
        />
      </div>
      <div className="border border-[#ddd] rounded overflow-x-auto">
        <TicketsTable
          isLoading={props.isLoading}
          profileId={props.profileId}
          tickets={props.tickets}
          search={props.search}
        />
      </div>
    </>
  )
}

const TicketsTable: VFC<{
  profileId: string

  tickets: null | ITicket[]
  isLoading: boolean
  search: string
}> = (props) => {
  if (props.isLoading) {
    return <AppProgressBar />
  }

  if (props.tickets === null) return null

  if (props.tickets.length === 0)
    return (
      <div>
        <h4 className="text-center text-[#777777]">
          <em>{props.search ? 'لا تتوفر نتائج.' : 'لا توجد لديك أي رسالة'}</em>
        </h4>
      </div>
    )

  return (
    <table className="w-full">
      <thead className="bg-[#ededed] border-[#ddd] border-b text-right">
        <tr className="p-2">
          <th className="p-2">من</th>
          <th className="p-2">الموضوع</th>
          <th className="p-2">التاريخ</th>
          <th className="p-2 whitespace-nowrap">مقتطف من آخر رسالة</th>
        </tr>
      </thead>
      <tbody>
        {props.tickets.map((ticket) => {
          return (
            <TicketRow
              key={ticket._id}
              ticket={ticket}
              profileId={props.profileId}
            />
          )
        })}
      </tbody>
    </table>
  )
}

const TicketRow: VFC<{ ticket: ITicket; profileId: string }> = (props) => {
  const history = useHistory()

  const handleClick = useCallback(() => {
    history.push('/tickets/' + props.ticket._id)
  }, [history, props.ticket._id])

  const isUnread =
    !props.ticket.emails[0].readDate &&
    props.ticket.emails[0].from._id !== props.profileId

  return (
    <tr
      id={props.ticket._id}
      onClick={handleClick}
      onKeyPress={(e) => {
        e.key === 'Enter' && handleClick()
      }}
      tabIndex={0}
      role="button"
      className="cursor-pointer"
    >
      <TicketTd isUnread={isUnread}>
        {props.ticket.emails[0].from.firstName +
          ' ' +
          props.ticket.emails[0].from.lastName}
        <span>
          {props.ticket.emails[0].from._id === props.profileId ? ' (أنا) ' : ''}
        </span>
      </TicketTd>
      <TicketTd isUnread={isUnread}>
        <span>
          {props.ticket.subject
            ? props.ticket.subject
            : props.ticket.ticket
            ? props.ticket.ticket.subject
            : ''}
        </span>{' '}
        <span>
          {props.ticket.emails.length > 1 &&
            `(${props.ticket.emails.length} رسائل)`}
        </span>
        &nbsp;
      </TicketTd>
      <TicketTd isUnread={isUnread}>
        {Helper.date(props.ticket.emails[0].date)}
      </TicketTd>
      <TicketTd isUnread={isUnread}>
        {props.ticket.emails[0].body
          .replace(/\s+/g, ' ')
          .split(/(?=\s)/gi)
          .slice(0, 10)
          .join('') + '...'}
      </TicketTd>
    </tr>
  )
}

const TicketTd: FC<{ isUnread: boolean }> = (props) => {
  return (
    <td
      className={`text-[#358ed7] p-2 align-top ${
        props.isUnread ? 'font-bold bg-[#f6fbff] border-[#358ed7] border-b' : ''
      }`}
    >
      {props.children}
    </td>
  )
}

const UnreadBadge: VFC<{ unread: number }> = (props) => {
  if (props.unread) {
    return (
      <span className="bg-[#777777] inline-block min-w-[26px] py-[3px] px-2 text-sm font-bold text-white whitespace-nowrap  text-center rounded-2xl">
        {props.unread}
      </span>
    )
  }
  return null
}
