import React, { useCallback, useState, VFC } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useProfile } from '../../contexts/ProfileContext'
import { LoginUi } from './Login.ui'

export const Login: VFC = () => {
  const history = useHistory()
  const { login } = useProfile()
  const location = useLocation<{ from: { pathname: string } }>()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.target.name === 'email') setEmail(event.target.value)
      else setPassword(event.target.value)
    },
    []
  )

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      event.preventDefault()
      setIsLoading(true)

      const res = await login({ email, password })
      setIsLoading(false)
      if (!res) {
        return
      }

      if (typeof res === 'string') {
        setError(res)
        return
      }
      history.push(location?.state?.from?.pathname || '/')
    },
    [login, email, password, history, location]
  )

  return (
    <LoginUi
      isLoading={isLoading}
      error={error}
      email={email}
      password={password}
      onChangeEmail={handleChange}
      onChangePassword={handleChange}
      onSubmit={handleSubmit}
    />
  )
}
