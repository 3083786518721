import React, { VFC } from 'react'
import { Redirect } from 'react-router'

interface Props {
  id: string | null
  resetToken: string | null
  valid: boolean | null
}

export const ActivationUi: VFC<Props> = (props) => {
  if (props.valid === true)
    return <Redirect to={`/reset/${props.id}/${props.resetToken}/1`} />

  if (props.valid === false) return <Redirect to={'/login'} />

  return <h4>المرجو الانتظار...</h4>
}
