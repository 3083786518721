import { Moment } from 'moment'
import { useCallback, VFC } from 'react'

import { Redirect } from 'react-router'

import swal from 'sweetalert2'

import '../../stylesheets/packages.css'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { AppProgressBar } from '../../components/layout/AppProgressBar'
import StripePay from '../../components/StripePay'
import vodaphoneCash from '../../images/vodaphone-cash.png'
import Config from '../../config'
import { Link } from 'react-router-dom'

const stripePromise = loadStripe(Config.stripe_pk)

interface Props {
  packages: string[]
  duration: string
  isLoading: boolean
  currency: string
  currencyCode: string
  showPromo: boolean
  amount:
    | {
        yearly: number
        quarterly: number
      }
    | undefined
  promotionLabel: string
  expirationDate: Moment
}
export const ToPaymentUi: VFC<Props> = (props) => {
  const showVodaphoneCash = useCallback(() => {
    swal({
      text: '',
      confirmButtonText: 'إخفاء',
      html: `
      <Image src="${vodaphoneCash}" style="width: 50%;margin: 0 0 30px;border-radius: 5px;" />
      <div style="text-align:justify;">
      <p>
        يمكنكم الدفع أيضا بواسطة خدمة "فودافون كاش" وذلك  عن طريق تحويل مبلغ الاشتراك إلى الرقم التالي: (<a href="tel:01026444664">01026444664</a>)، علما بأن عروض الاشتراك المتاحة حاليا هي:
        <br/>
        -	250 جنيها مصريا للاشتراك لمدة 3 أشهر؛
        -	850 جنيها مصريا للاشتراك لمدة سنة واحدة.
        <br/>
        <br/>
        ملاحظة: عند تحويل مبلغ الاشتراك عن طريق "فودافون كاش"، ستتوصلون برمز قسيمة الاشتراك خلال أقل من 24 ساعة بإذن الله.
        <br/>
        <br/>
        <small>
        <b>كيفية استخدام رمز القسيمة:</b>
        <br/>
        بمجرد حصولكم على رمز القسيمة عبر الهاتف المحمول، يمكنكم النقر على أيقونة "استعمل القسيمة" الموجودة في أعلى صفحة لوحة تحكم الآباء من أجل إدخاله، وبعدها سيتم تفعيل اشتراككم مباشرة.
        </small>
      </p>
      </div>`,
    })
  }, [])

  if (props.packages.length === 0) return null
  if (props.packages.indexOf(props.duration) === -1)
    return <Redirect push to="/packages" />

  return (
    <>
      {props.isLoading && <AppProgressBar />}
      <h2 className="page-title">تأكيد الاشتراك</h2>
      <div>
        <div className="packages-container">
          {props.amount && (
            <div className="package" style={{ width: '100%', height: 'auto' }}>
              {props.duration === 'quarterly' ? (
                <div className="package-price">
                  {props.amount['quarterly']}
                  <sup>{props.currency}</sup>
                </div>
              ) : (
                <div className="package-price">
                  <sub>{props.amount['quarterly'] * 4}</sub>
                  {props.amount['yearly']}
                  <sup>{props.currency}</sup>
                </div>
              )}
              <div className="package-duration">
                <table style={{ width: '100%' }} className="text-right">
                  <tbody>
                    <tr>
                      <td style={{ width: '50%' }}>مدة الاشتراك :</td>
                      <td>
                        <strong className="text-success">
                          {props.duration === 'quarterly' ? '3 أشهر' : 'سنة'}
                        </strong>
                      </td>
                    </tr>
                    {props.showPromo && (
                      <tr>
                        <td>الحصول مجانا على :</td>
                        <td>
                          <strong className="text-success">
                            {props.promotionLabel}
                          </strong>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>انتهاء الاشتراك في :</td>
                      <td>
                        <strong className="text-success">
                          {props.expirationDate.format('YYYY-MM-DD').toString()}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="stripe-pay">
                <Elements stripe={stripePromise}>
                  <StripePay pkg={props.duration} />
                </Elements>
              </div>
              {false && (
                <p>
                  سيتم تحويلكم لصفحة انهاء اجراءات الدفع، ثم بعد ذلك سيعاد نقلكم
                  الى صفحة الآباء
                </p>
              )}
              {props.currencyCode === 'egp' && (
                <h3
                  style={{ color: '#ee1c24', cursor: 'pointer' }}
                  onClick={showVodaphoneCash}
                >
                  <img
                    className="nav-link"
                    style={{ height: 20, borderRadius: 5, margin: '0 10px' }}
                    alt="عالم زيدو"
                    src={vodaphoneCash}
                  />
                  اضغط هنا لخدمة فودافون كاش
                </h3>
              )}
            </div>
          )}
        </div>
        <div className="packages-note" style={{ fontSize: '1em' }}>
          <Link to={`/packages`}>
            <span className="fa fa-chevron-right"></span>
            &nbsp; الرجوع إلى صفحة الأثمنة
          </Link>
        </div>
      </div>
    </>
  )
}
