import React, { VFC } from 'react'
import { useProfile } from '../contexts/ProfileContext'
import logo from '../images/logo.svg'

import { AppNavLink } from './AppNavLink'
import { AppProfileMenu } from './layout/AppProfileMenu'
import { AppSideMenu } from './layout/AppSideMenu'

export const Header: VFC = () => {
  const { logout, profile } = useProfile()

  return (
    <div className="mb-4 border-b-2 border-b-[#101010] bg-[#34495e]">
      <div className="flex items-center px-4 py-4">
        <div className="flex-1 items-center hidden sm:flex">
          <a href="http://www.zidoworld.com" className="ml-2">
            <img className="w-[80px]" alt="عالم زيدو" src={logo} />
          </a>
          <AppNavLink to={'/'} icon="fa fa-child" label={'أطفالي'} />
          <AppNavLink to={'/tickets'} icon="fa fa-envelope" label={'الرسائل'} />
          <AppNavLink to={'/posts'} icon="fa fa-book" label={'مكتبة زيدو'} />
        </div>
        <div className="whitespace-nowrap hidden sm:block">
          <AppProfileMenu label={profile?.firstName || ''} onLogout={logout} />
        </div>
        <div className="block sm:hidden">
          <AppSideMenu />
        </div>
      </div>
    </div>
  )
}
