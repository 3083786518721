import React, { VFC } from 'react'
import { Link } from 'react-router-dom'
import { ButtonBlue } from '../../components/buttons/Buttons'
import { AppFormLayout } from '../../components/layout/AppFormLayout'
import { AppProgressBar } from '../../components/layout/AppProgressBar'
import { FieldGroup2 } from '../../components/Widgets'
import Config from '../../config'

export const LoginUi: VFC<{
  email: string
  password: string
  isLoading: boolean
  error: null | string

  onChangeEmail: React.ChangeEventHandler<HTMLInputElement>
  onChangePassword: React.ChangeEventHandler<HTMLInputElement>
  onSubmit: React.FormEventHandler<HTMLFormElement>
}> = (props) => {
  return (
    <>
      {props.isLoading && <AppProgressBar />}
      <AppFormLayout title="تسجيل الدخول للآباء">
        <ErrorMessage error={props.error} />
        <form className="max-w-lg mx-auto" onSubmit={props.onSubmit}>
          <div className="mb-4">
            <FieldGroup2
              label="البريد"
              type="email"
              name="email"
              value={props.email}
              onChange={props.onChangeEmail}
              required
            />
          </div>
          <div className="mb-4">
            <FieldGroup2
              label="كلمة المرور"
              type="password"
              name="password"
              value={props.password}
              onChange={props.onChangePassword}
              required
            />
          </div>
          <Link to="/forgotpassword" className="text-[#337ab7]">
            نسيت كلمة المرور؟
          </Link>
          <div className="my-4 max-w-xs mx-auto">
            <ButtonBlue fullWidth type="submit" label="دخول" />
          </div>
          <RegisterLink />
        </form>
      </AppFormLayout>
    </>
  )
}

const ErrorMessage: VFC<{ error?: string | null }> = ({ error }) => {
  return (
    <div className="text-[#f6580f] text-center">
      {error ? error + '. المرجو إعادة المحاولة.' : ''}
    </div>
  )
}

function RegisterLink() {
  return (
    <div className="text-center">
      <span className="text-[#4a4a4a]">ليس لدي حساب.</span>
      <a href={Config.registrationLink} className="font-bold text-[#337ab7]">
        {' '}
        إنشاء حساب جديد
      </a>
    </div>
  )
}
