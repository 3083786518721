import React from 'react'
import Config from '../../config'
import { PostsUi } from './Posts.ui'
import { WordpressPost } from '../../interfaces.ui'

export default class Posts extends React.Component<
  {},
  {
    categories?: { id: number; name: string }[]
    categoryId: string | number
    filtredPosts: WordpressPost[]
    currentPage: number
    allPages: number | null
    search: string
  }
> {
  constructor(props: {}) {
    super(props)
    this.state = {
      search: '',
      categories: undefined,
      categoryId: 0,
      filtredPosts: [],
      currentPage: 1,
      allPages: null,
    }
  }

  makePagination(data: Response) {
    this.setState({ allPages: Number(data.headers.get('X-WP-TotalPages')) })
  }

  getPosts = () => {
    const pageNumber = this.state.currentPage

    const filter = this.state.search
    if (Number(this.state.categoryId) !== 0) {
      let postsUrl =
        'https://www.zidoworld.com/wp-json/wp/v2/posts?categories=' +
        this.state.categoryId
      if (filter) {
        postsUrl =
          postsUrl +
          '&filter&per_page=' +
          Config.postConfig.paginationPerPage +
          '&search=' +
          filter +
          '&page=' +
          pageNumber +
          '&_embed'
      } else {
        postsUrl =
          postsUrl +
          '&per_page=' +
          Config.postConfig.paginationPerPage +
          '&page=' +
          pageNumber +
          '&_embed'
      }
      this.setState({ currentPage: pageNumber })

      fetch(postsUrl).then((response) => this.makePagination(response))

      fetch(postsUrl)
        .then((response) => response.json())
        .then((response) => {
          this.setState({ filtredPosts: response })
        })
    } else {
      let url = 'https://www.zidoworld.com/wp-json/wp/v2/posts'
      if (filter) {
        url =
          url +
          '?filter&per_page=' +
          Config.postConfig.paginationPerPage +
          '&search=' +
          filter +
          '&page=' +
          pageNumber +
          '&_embed'
      } else {
        url =
          url +
          '?per_page=' +
          Config.postConfig.paginationPerPage +
          '&page=' +
          pageNumber +
          '&_embed'
      }
      this.setState({ currentPage: pageNumber })

      fetch(url).then((response) => this.makePagination(response))

      fetch(url)
        .then((res) => res.json())
        .then((response) => {
          this.setState({ filtredPosts: response })
        })
    }
  }

  componentDidMount() {
    this.getPosts()
    const categoriesUrl = 'https://www.zidoworld.com/wp-json/wp/v2/categories'
    fetch(categoriesUrl)
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          categories: response,
        })
      })
  }
  /** the change of category */
  handleChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    let catId = parseInt(e.target.value, 10)

    if (catId !== 0) {
      const postsUrl =
        'https://www.zidoworld.com/wp-json/wp/v2/posts?categories=' +
        catId +
        '&per_page=' +
        Config.postConfig.paginationPerPage +
        '&page=1&_embed'
      this.setState({ categoryId: catId, currentPage: 1 })

      fetch(postsUrl).then((response) => this.makePagination(response))

      fetch(postsUrl)
        .then((response) => response.json())
        .then((response) => {
          this.setState({ filtredPosts: response, categoryId: catId })
        })
    } else {
      this.setState({ categoryId: 0, currentPage: 1 }, () => {
        this.getPosts()
      })
    }
  }

  handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    this.setState({ currentPage: 1, search: e.target.value }, () => {
      this.getPosts()
    })
  }

  handleChangePage: (page: number) => void = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getPosts()
    })
  }

  render() {
    return (
      <PostsUi
        allPages={this.state.allPages}
        currentPage={this.state.currentPage}
        filtredPosts={this.state.filtredPosts}
        onChangeCategory={this.handleChange}
        onChangePage={this.handleChangePage}
        onSearch={this.handleSearch}
        search={this.state.search}
        categories={this.state.categories}
      />
    )
  }
}
