import React, { DetailedHTMLProps, FC, ReactElement, VFC } from 'react'

type Props =
  | {
      id?: string
      label?: string
      error?: string | string[]
      help?: string
      name?: string
      type?: string
      placeholder?: string
      value?: string
      disabled?: boolean
      onChange?: React.ChangeEventHandler<HTMLInputElement>
      addOn?: string | ReactElement
      required?: boolean
    }
  | {
      id?: string
      label?: string
      help?: string
      error?: string | string[]
      name?: string
      placeholder?: string
      value?: string
      disabled?: boolean
      onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
      addOn?: string | ReactElement
      required?: boolean
      rows: number
    }

export const FieldGroup2: VFC<Props> = (props) => {
  const hasError =
    typeof props.error === 'string'
      ? !!props.error
      : Array.isArray(props.error)
      ? !!props.error.length
      : false
  return (
    <label className="w-full">
      {props.label && <div className="font-bold mb-1">{props.label}</div>}
      <div
        className={`flex rounded  border transition-all ${
          props.disabled ? 'shadow-none' : 'shadow-md'
        } ${
          hasError
            ? 'border-red-500 focus-within:border-red-500 focus-within:shadow-red-500'
            : 'border-[#ccc] focus-within:border-[#66afe9] focus-within:shadow-[#66afe9]'
        }`}
      >
        {props.addOn && (
          <div className="rounded-r px-2 flex items-center justify-center text-[#495057] bg-[#e9ecef] border-l border-[#ced4da]">
            <span className="">{props.addOn}</span>
          </div>
        )}
        {'rows' in props ? (
          <textarea
            required={props.required}
            disabled={props.disabled}
            name={props.name}
            className={`${
              props.addOn ? 'rounded-l' : 'rounded'
            } h-auto disabled:bg-[#eee] disabled:cursor-not-allowed  px-3 block w-full bg-white text-[#555555] outline-none`}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            rows={props.rows}
          />
        ) : (
          <input
            required={props.required}
            disabled={props.disabled}
            name={props.name}
            type={props.type || 'text'}
            className={`
          disabled:bg-[#eee] disabled:cursor-not-allowed 
       
          ${
            props.addOn ? 'rounded-l' : 'rounded'
          } px-3 block w-full h-8 bg-white text-[#555555] outline-none`}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
          />
        )}
      </div>
      {props.help && <div className="text-blue-800 mt-1">{props.help}</div>}
      {typeof props.error === 'string' && !!props.error ? (
        <div className="text-red-500 mt-1">{props.error}</div>
      ) : Array.isArray(props.error) && props.error.length ? (
        <ul className="text-red-500 mt-1">
          {props.error.map((err) => {
            return <li key={err}>{err}</li>
          })}
        </ul>
      ) : null}
    </label>
  )
}

export const Select: FC<
  DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >
> = (props) => {
  return (
    <select
      {...props}
      className={
        'cursor-pointer rounded px-3 block w-full h-8 bg-white text-[#555555] border transition-all shadow-md  outline-none border-[#ccc] focus:border-[#66afe9] focus:shadow-[#66afe9] '
      }
    >
      {props.children}
    </select>
  )
}
