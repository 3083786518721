import React from 'react'
import swal from 'sweetalert2'
import Helper from '../../utilis/Helpers'
import { ForgotPasswordUi } from './ForgotPassword.ui'

export default class ForgotPassword extends React.Component<
  {},
  {
    email: string
    submitted: boolean
  }
> {
  constructor(props: {}) {
    super(props)

    this.state = {
      email: '',
      submitted: false,
    }
  }

  handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    this.setState({ email: event.target.value })
  }

  componentDidMount() {
    Helper.logOut()
  }

  handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()

    let url = '/auth/reset'
    var that = this

    Helper.ApiRequest(url, {
      method: 'POST',
      body: JSON.stringify({
        email: that.state.email,
      }),
    })
      .then((res) => {
        that.setState({ submitted: true })
      })
      .catch(function (err) {
        swal({
          title: '',
          text: 'لا يوجد حساب مسجل بهذا البريد الإلكتروني. المرجو إعادة المحاولة',
          type: 'error',
          confirmButtonText: 'إخفاء',
        })
      })
  }

  render() {
    return (
      <ForgotPasswordUi
        email={this.state.email}
        onChangeEmail={this.handleChange}
        onSubmit={this.handleSubmit}
        submitted={this.state.submitted}
      />
    )
  }
}
