import React, { ChangeEventHandler, FormEventHandler, VFC } from 'react'
import { ButtonBlue } from '../../components/buttons/Buttons'
import { AppFormLayout } from '../../components/layout/AppFormLayout'
import { FieldGroup2 } from '../../components/Widgets'

export const ForgotPasswordUi: VFC<{
  email: string
  submitted: boolean
  onChangeEmail: React.ChangeEventHandler<HTMLInputElement>
  onSubmit: React.FormEventHandler<HTMLFormElement>
}> = (props) => {
  return (
    <AppFormLayout title="نسيت كلمة المرور؟">
      {props.submitted ? (
        <SuccessMessage />
      ) : (
        <ForgotPasswordForm
          email={props.email}
          onChangeEmail={props.onChangeEmail}
          onSubmit={props.onSubmit}
        />
      )}
    </AppFormLayout>
  )
}

const SuccessMessage: VFC = () => {
  return (
    <>
      <i className="fa fa-check-circle"></i>
      <span className="text-lg">
        لتعيين كلمة مرور جديدة يرجى الضغط على الرابط المرسل إلى بريدكم
        الإلكتروني
      </span>
    </>
  )
}
const ForgotPasswordForm: VFC<{
  onSubmit: FormEventHandler<HTMLFormElement>
  email: string
  onChangeEmail: ChangeEventHandler<HTMLInputElement>
}> = (props) => {
  return (
    <>
      <div className="text-gray-700 text-lg mb-4">
        لتعيين كلمة مرور جديدة، يرجى كتابة بريدكم الإلكتروني
      </div>
      <form onSubmit={props.onSubmit}>
        <FieldGroup2
          type="text"
          name="email"
          label="البريد الإلكتروني"
          value={props.email}
          onChange={props.onChangeEmail}
          required
        />

        <div className="mt-4 max-w-xs mx-auto">
          <ButtonBlue fullWidth label="إرسال" type="submit" />
        </div>
      </form>
    </>
  )
}
