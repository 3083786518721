import React from 'react'
import swal from 'sweetalert2'
import Config from '../../config'
import { IAccountReportsResponse } from '../../interface.api'
import { WordpressPost } from '../../interfaces.ui'
import Helper from '../../utilis/Helpers'
import { HomeUi } from './Home.ui'

export default class Home extends React.Component<
  {},
  {
    showModal: boolean
    edit: false | string
    kids: null | IAccountReportsResponse[]
    isLoading: boolean
    posts: WordpressPost[]
  }
> {
  constructor(props: {}) {
    super(props)
    this.state = {
      showModal: false,
      edit: false,
      kids: null,
      isLoading: true,
      posts: [],
    }

    this.openForm = this.openForm.bind(this)
    this.closeForm = this.closeForm.bind(this)
    this.delete = this.delete.bind(this)
    this.edit = this.edit.bind(this)
    this.getData = this.getData.bind(this)
    this.kidsLoging = this.kidsLoging.bind(this)
  }

  componentDidMount() {
    this.getData()
    const postsUrl =
      'https://www.zidoworld.com/wp-json/wp/v2/posts?per_page=' +
      Config.homeConfig.numberArticle +
      '&_embed'
    fetch(postsUrl)
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          posts: response,
        })
      })
  }

  async getData() {
    let me = Helper.getStoredProfile()
    if (!me) return
    let url = `/accounts/${me._id}/reports`
    var that = this
    that.setState({ isLoading: true }) // show loading screen

    Helper.ApiRequest<IAccountReportsResponse[]>(url).then(function (kids) {
      if (typeof kids !== 'object' || 'error' in kids) {
        that.setState({ isLoading: false })
        return
      }
      that.setState({ kids, isLoading: false })
    })
  }

  openForm() {
    this.setState({ showModal: true })
  }

  closeForm() {
    this.setState({ showModal: false, edit: false })
  }

  edit(id: string) {
    this.setState({ showModal: true, edit: id })
  }

  kidsLoging(kid: IAccountReportsResponse) {
    let username = kid.username
    let token = kid.authToken
    let url = `${Config.kidsURL}/login/${username}/${token}`

    window.open(url, '_blank')
  }

  delete(id: string) {
    let displayName

    if (!this.state.kids) return

    for (let i = 0; i < this.state.kids.length; i++) {
      let kid = this.state.kids[i]
      if (kid.id === id) {
        displayName = kid.displayName
        break
      }
    }

    swal({
      title: ' المرجو التأكيد ',
      text: ' أنت على وشك حذف الطفل "' + displayName + '"',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'حذف',
      cancelButtonText: 'إلغاء',
    }).then((result) => {
      if (result.value === true) {
        let url = '/kids/' + id
        var that = this
        that.setState({ isLoading: true }) // show loading screen
        Helper.ApiRequest(url, { method: 'DELETE' })
          .then(function (kid) {
            swal({
              title: '',
              text: 'لقد تم الحذف بنجاح',
              type: 'success',
              confirmButtonText: 'إخفاء',
            })

            that.setState({ isLoading: false }) // hide loading screen
            that.getData()
          })
          .then(function () {
            Helper.accessLog(null, null, 'delete child', true)
          })
      }
    })
  }

  render() {
    return (
      <HomeUi
        closeForm={this.closeForm}
        edit={this.state.edit}
        isLoading={this.state.isLoading}
        kids={this.state.kids}
        onDelete={this.delete}
        onEdit={this.edit}
        onLogin={this.kidsLoging}
        onRefresh={this.getData}
        openForm={this.openForm}
        posts={this.state.posts}
        showModal={this.state.showModal}
      />
    )
  }
}
