import React, { useEffect, useState, VFC } from 'react'
import { useProfile } from '../../contexts/ProfileContext'
import { IPaymentsDetailsApi } from '../../interface.api'
import Helper from '../../utilis/Helpers'
import { PackagesUi } from './Packages.ui'

interface Details {
  currency: string
  amount: {
    quarterly: number
    yearly: number
  }
}

const Packages: VFC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [details, setDetails] = useState<Details>()

  const { profile } = useProfile()

  useEffect(() => {
    Helper.ApiRequest<IPaymentsDetailsApi>('/payments/details').then(
      (pConf) => {
        setIsLoading(false)

        if (typeof pConf === 'boolean') return
        if ('error' in pConf) return

        setDetails({
          amount: {
            quarterly: pConf.amount.quarterly,
            yearly: pConf.amount.yearly,
          },
          currency: pConf.currency,
        })
      }
    )
  }, [])

  const showPromo = isLoading ? false : !!profile?.expires

  return (
    <PackagesUi details={details} isLoading={isLoading} showPromo={showPromo} />
  )
}

export default Packages
