import React from 'react'
import { RouteComponentProps } from 'react-router'
import { IAuthActivate } from '../../interface.api'

import Helper from '../../utilis/Helpers'
import { ActivationUi } from './Activation.ui'

type Props = RouteComponentProps<{ id: string; code: string }> & {}
export default class Activation extends React.Component<
  Props,
  { id: string | null; resetToken: string | null; valid: boolean | null }
> {
  constructor(props: Props) {
    super(props)

    this.state = {
      id: null,
      resetToken: null,
      valid: null,
    }
  }

  componentDidMount() {
    let url = '/auth/activate/'
    var that = this

    Helper.ApiRequest<IAuthActivate>(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: this.props.match.params.id,
        code: this.props.match.params.code,
      }),
    })
      .then((res) => {
        if (typeof res !== 'object' || 'error' in res) {
          return Promise.reject()
        }

        that.setState({ valid: true, id: res.id, resetToken: res.resetToken })
      })
      .catch(function (err) {
        that.setState({ valid: false })
      })
  }

  render() {
    return (
      <ActivationUi
        id={this.state.id}
        resetToken={this.state.resetToken}
        valid={this.state.valid}
      />
    )
  }
}
