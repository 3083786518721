import React, { ChangeEventHandler, FormEventHandler, VFC } from 'react'
import swal from 'sweetalert2'
import { ButtonBlue, ButtonUnderlined } from '../components/buttons/Buttons'
import { AppModal } from '../components/modal/modal'
import { FieldGroup2 } from '../components/Widgets'
import Config from '../config'

import Helper from '../utilis/Helpers'

interface Props {
  close: () => void
  title?: string
}
export default class Unsubscribe extends React.Component<
  Props,
  {
    showModal: boolean
    reasons: string[]
    feedback: string
    error: string
  }
> {
  constructor(props: Props) {
    super(props)

    this.state = {
      showModal: true,
      reasons: [],
      feedback: '',
      error: '',
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeReason = this.handleChangeReason.bind(this)
  }

  handleChange: React.ChangeEventHandler<any> = (event) => {
    this.setState({ feedback: event.target.value })
  }

  handleChangeReason: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (this.state.error) this.setState({ error: '' })

    var reasons = this.state.reasons

    var pos = reasons.indexOf(event.target.value)

    if (event.target.checked === true && pos === -1) {
      reasons.push(event.target.value)
    } else if (event.target.checked === false && pos !== -1) {
      reasons.splice(pos, 1)
    }

    this.setState({
      reasons: reasons,
      error: '',
    })
  }

  handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    let that = this

    if (that.state.reasons.length < 1) {
      that.setState({ error: 'المرجو تفعيل أحد الاختيارات' })
      return
    }

    let reasons: string[] = []
    that.state.reasons.forEach((reason) => {
      reasons.push(
        (Config.unsubscribe.reasons as Record<string, string>)[reason]
      )
    })
    if (
      that.state.feedback !== '' &&
      this.state.reasons.indexOf('other-reasons') >= 0
    ) {
      reasons.push(that.state.feedback)
    }

    const me = Helper.getStoredProfile()
    if (!me) return
    let url = '/accounts/' + me._id

    Helper.ApiRequest(url, {
      method: 'DELETE',
      body: JSON.stringify({
        reasons: reasons,
        trigger: 'dashboard',
      }),
    })
      .then(function (res) {
        Helper.logOut()
        swal({
          title: '',
          text: 'لقد تم إغلاق الحساب بنجاح',
          type: 'success',
          confirmButtonText: 'إخفاء',
        }).then(function (res) {
          window.location.href = '/login'
        })
      })
      .catch(function (err) {
        swal({
          title: '',
          text: err,
          type: 'error',
          confirmButtonText: 'إخفاء',
        })
      })
  }

  render() {
    return (
      <AppModal
        title={this.props.title || 'إغلاق الحساب'}
        show={this.state.showModal}
        onClose={this.props.close}
      >
        <div className="p-4 text-right">
          <UnsubscribeForm
            error={this.state.error}
            feedback={this.state.feedback}
            onChangeFeedback={this.handleChange}
            onChangeReason={this.handleChangeReason}
            onClose={this.props.close}
            onSubmit={this.handleSubmit}
            showTextArea={this.state.reasons.indexOf('other-reasons') >= 0}
          />
        </div>
      </AppModal>
    )
  }
}

const UnsubscribeForm: VFC<{
  feedback: string
  onChangeFeedback: ChangeEventHandler<HTMLTextAreaElement>
  showTextArea: boolean
  error: string
  onSubmit: FormEventHandler<HTMLFormElement>
  onChangeReason: ChangeEventHandler<HTMLInputElement>
  onClose: () => void
}> = (props) => {
  return (
    <form onSubmit={props.onSubmit}>
      <div className="flex flex-col">
        <div className="font-bold mb-4">
          هل تريد بالفعل إخلاق حسابك؟ هذه عملية لا يمكن إلغاؤها بعد التأكيد.
        </div>
        <p className="mb-6">
          يؤسفنا أنك لم تعد مهتمًا بمنصة عالم زيدو وسنفتقدك. سنكون ممتنين إذا
          شاركتنا ملاحظاتك لتحسين خدماتنا.
        </p>
        <div className="font-bold mb-4">يُرجى تحديد السبب (الأسباب) أدناه:</div>
        <ul className="flex flex-col gap-2">
          {Config.unsubscribe.reasonsIds.map((id) => {
            return (
              <li key={id} className="">
                <label>
                  <input
                    type="checkbox"
                    value={id}
                    className="cursor-pointer ml-2 h-4 w-4 inline-block"
                    onChange={props.onChangeReason}
                  />
                  {(Config.unsubscribe.reasons as Record<string, string>)[id]}
                </label>
              </li>
            )
          })}
        </ul>
        {props.showTextArea && (
          <div className="mt-4">
            <FieldGroup2
              name="body"
              rows={8}
              label="تكرما اشرح بعض هذه الأسباب الأخرى"
              onChange={props.onChangeFeedback}
              value={props.feedback}
            />
          </div>
        )}

        {props.error && <div className="text-red-500 mt-1">{props.error}</div>}

        <div className="mt-6 flex">
          <div className="ml-2">
            <ButtonBlue type="submit" label="إغلاق" />
          </div>
          <ButtonUnderlined
            label="إلغاء"
            onClick={props.onClose}
            type="button"
          />
        </div>
      </div>
    </form>
  )
}
