import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import '../node_modules/font-awesome/css/font-awesome.min.css'
import './stylesheets/index.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { ProfileProvider } from './contexts/ProfileContext'
import { SWRConfig, BareFetcher } from 'swr'
import Helper from './utilis/Helpers'

const apiFetcher: BareFetcher = (url: string) => {
  return Helper.ApiRequest(url)
}

render(
  <BrowserRouter>
    <SWRConfig
      value={{
        fetcher: apiFetcher,
      }}
    >
      <ProfileProvider>
        <App />
      </ProfileProvider>
    </SWRConfig>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

if (!window.trackingsFunctions) window.trackingsFunctions = []
function track() {
  if (!window.trackingsFunctions) return
  window.trackingsFunctions.forEach((tFunc) => tFunc())
}

window.onpopstate = function (event) {
  track()
}
;(function (history) {
  var pushState = history.pushState
  history.pushState = function (state) {
    setTimeout(track, 500)
    // @ts-ignore
    return pushState.apply(history, arguments)
  }
})(window.history)
