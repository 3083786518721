import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { MouseEventHandler, useCallback, useState, VFC } from 'react'
import Helper from '../utilis/Helpers'

import { useProfile } from '../contexts/ProfileContext'
import './Stripe.css'

const StripePay: VFC<{ pkg: string }> = (props) => {
  const { profile } = useProfile()

  const stripe = useStripe()
  const elements = useElements()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const submit: MouseEventHandler<HTMLButtonElement> = useCallback(
    (ev) => {
      if (!stripe || !elements) return

      ev.stopPropagation()

      setIsLoading(true)
      setError(null)

      const cardElement = elements.getElement(CardElement)

      if (!cardElement) return

      stripe.createToken(cardElement).then((result) => {
        if (result.error) {
          setError(result.error.message || '')
          setIsLoading(false)
          return
        }
        let token = result.token

        Helper.ApiRequest<{ success: true }, string>('/payments/charge', {
          method: 'POST',
          body: JSON.stringify({
            token: token.id,
            pkg: props.pkg,
          }),
        })
          .then((result) => {
            if (typeof result === 'boolean') {
              setIsLoading(false)
              return
            }

            if ('error' in result) {
              setError(result.message)
              setIsLoading(false)
              return
            }

            if (profile?.trackingConsent)
              // if consent go to thankyou page
              window.location.href = '/thank_you.html'
            // if not refresh the main page
            else window.location.href = '/'
          })
          .catch((error) => {
            setIsLoading(false)
          })
      })
    },
    [profile, stripe, props.pkg, elements]
  )

  return (
    <div className="checkout">
      المرجو إدخال معلومات البطاقة
      <CardElement options={{ style: { base: { fontSize: '16px' } } }} />
      {error && <p className="StripeElement-Error">{error}</p>}
      <button disabled={isLoading} className="action-btn" onClick={submit}>
        {isLoading ? 'المرجو الانتظار، جاري التحقق من البطاقة ...' : 'ادفع'}
      </button>
    </div>
  )
}

export default StripePay
