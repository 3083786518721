import moment from 'moment'
import React, { VFC } from 'react'
import { Link } from 'react-router-dom'
import { ButtonKidLogin } from '../../components/buttons/ButtonKidLogin'
import { ButtonBlue } from '../../components/buttons/Buttons'
import { AppProgressBar } from '../../components/layout/AppProgressBar'
import { WordpressPostCard } from '../../components/WordpressPostCard'
import Config from '../../config'
import UserModal from '../../containers/UserModal'
import fAvatar from '../../images/avatars/f_avatar.png'
import mAvatar from '../../images/avatars/m_avatar.png'
import { IAccountReportsResponse } from '../../interface.api'
import { WordpressPost } from '../../interfaces.ui'

interface Props {
  openForm: () => void
  closeForm: () => void
  onEdit: (id: string) => void
  onDelete: (id: string) => void
  onLogin: (kid: IAccountReportsResponse) => void
  onRefresh: () => void
  showModal: boolean
  edit: false | string
  kids: null | IAccountReportsResponse[]
  isLoading: boolean
  posts: WordpressPost[]
}
export const HomeUi: VFC<Props> = (props) => {
  return (
    <>
      {(props.isLoading || !props.kids) && <AppProgressBar />}
      {props.kids ? (
        props.kids.length === 0 ? (
          <NoKidContent openForm={props.openForm} />
        ) : (
          <KidsList {...props} />
        )
      ) : null}

      {!!props.posts?.length && (
        <>
          <h3 className="text-2xl mt-10 mb-4">آخر مقالات مكتبة زيدو</h3>

          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            {props.posts.map((post) => {
              return (
                <WordpressPostCard
                  linkToPosts
                  key={post.guid.rendered}
                  post={post}
                />
              )
            })}
          </div>
        </>
      )}

      {props.showModal && (
        <UserModal
          title={props.edit ? 'تعديل' : 'إضافة'}
          refresh={props.onRefresh}
          edit={props.edit || ''}
          close={props.closeForm}
        />
      )}
    </>
  )
}

const GridHeader: VFC<{ text: string }> = (props) => {
  return (
    <div className="bg-[#ededed]">
      <h5 className="font-bold p-3">{props.text}</h5>
    </div>
  )
}

const GridIconButton: VFC<{ onClick: () => void; icon: string }> = (props) => {
  return (
    <button
      className="w-[48px] h-[48px] text-2xl text-[#358ed7] hover:text-blue-700"
      onClick={props.onClick}
    >
      <i className={props.icon} aria-hidden="true"></i>
    </button>
  )
}

const AddKidButton: VFC<{ disabled?: boolean; onClick: () => void }> = (
  props
) => {
  return (
    <ButtonBlue
      icon="fa fa-plus"
      label="إضافة طفل جديد"
      onClick={props.onClick}
      disabled={props.disabled}
    />
  )
}

const NoKidContent: VFC<Pick<Props, 'openForm'>> = (props) => {
  return (
    <>
      <div className="mb-10 flex flex-col items-center border-[#d4d4d4] border rounded text-center p-4">
        <i
          className="mb-2 text-5xl text-gray-500 fa fa-exclamation-circle"
          aria-hidden="true"
        ></i>
        <p className="text-lg mb-2">
          ليس لديك أطفال, للمتابعة قم بإضافة طفل جديد
        </p>

        <AddKidButton onClick={props.openForm} />

        <h4 className="mt-5 mb-2 text-lg">
          شاهد فيديو كيفية تسجيل الدخول وتسجيل الأبناء في عالم زيدو
        </h4>
        <iframe
          title="how-to-video"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/yrjCcNPSzY4?rel=0"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          className="rounded w-full max-w-[560px]"
        ></iframe>
      </div>
    </>
  )
}

const KidsList: VFC<Props> = (props) => {
  return (
    <div>
      <div className="flex justify-between mb-4">
        <h3 className="text-2xl">إدارة الأطفال</h3>
        {props.kids && (
          <AddKidButton
            onClick={props.openForm}
            disabled={props.kids.length >= Config.maxKids}
          />
        )}
      </div>

      <div className="grid grid-cols-1 gap-4">
        {props.kids?.map((kid, index) => (
          <div
            key={kid.id}
            className={`flex border-[#d4d4d4] border rounded-lg shadow-md shadow-gray-700`}
          >
            <div className="relative lg:w-1/5 w-full sm:w-1/4 sm:border-l-[#d4d4d4] sm:border-l sm:flex sm:flex-col ">
              {index === 0 && <GridHeader text="الأطفال" />}

              <div className="flex flex-col p-2 flex-1">
                <div className="flex items-center flex-row sm:flex-col md:flex-row">
                  <div className="w-[80px]">
                    <img
                      width={72}
                      height={72}
                      src={kid.gender === 'female' ? fAvatar : mAvatar}
                      className="border-[#d6d6d6] inline-block border-solid border-4 rounded-full"
                      alt=""
                    />
                  </div>
                  <div className="flex-1">
                    <h4 className="text-lg ">{kid.displayName}</h4>
                    <span>المستوى {kid.level}</span>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="w-[80px] flex justify-center">
                    <GridIconButton
                      icon="fa fa-pencil"
                      onClick={() => props.onEdit(kid.id)}
                    />
                    <GridIconButton
                      icon="fa fa-trash"
                      onClick={() => props.onDelete(kid.id)}
                    />
                  </div>
                </div>
                <div className="mt-2 sm:hidden">
                  <Link
                    className="text-[#337ab7] font-bold text-lg border border-[#337ab7] rounded w-full block text-center py-1 px-2"
                    to={`/report/${kid.id}`}
                  >
                    تقرير كامل
                  </Link>
                </div>
                <div className="flex-1 mt-2 flex ">
                  <ButtonKidLogin onClick={() => props.onLogin(kid)} />
                </div>
              </div>
            </div>

            <div className="relative hidden sm:flex sm:flex-col sm:flex-1 sm:w-full">
              {index === 0 && <GridHeader text="ملخص" />}

              <div className="p-2 flex-1 flex flex-col justify-between">
                <div className="grid grid-cols-2 sm:grid-cols-5">
                  <div className="text-center">
                    <p className="text-[#777777] mb-2">
                      <i className="fa fa-clock-o" aria-hidden="true"></i>
                      <span className="">آخر ظهور</span>
                    </p>

                    {kid.lastSeen ? (
                      <h4>{moment.utc(kid.lastSeen).format('YYYY/MM/DD')}</h4>
                    ) : (
                      <span className="block text-center text-2xl">-</span>
                    )}
                  </div>
                  <div className="hidden sm:block text-center">
                    <div>
                      <p className="text-[#777777] mb-2">
                        <i className="fa fa-play-circle" aria-hidden="true"></i>
                        <span className="">القصص المشاهدة</span>
                      </p>
                      <span className="block text-center text-2xl">
                        {kid.watchCount}
                      </span>
                    </div>
                  </div>
                  <div className="hidden sm:block text-center">
                    <div>
                      <p className="text-[#777777] mb-2 ">
                        <i className="fa fa-tasks" aria-hidden="true"></i>
                        <span className="">آخر الأهداف التربوية</span>
                      </p>
                      <ol className="text-justify">
                        {kid.eduTargets.length > 0 ? (
                          kid.eduTargets.map((item, itemK) => (
                            <li key={itemK}>{item}</li>
                          ))
                        ) : (
                          <span className="block text-center text-2xl">-</span>
                        )}
                      </ol>
                    </div>
                  </div>
                  <div className="hidden sm:block text-center">
                    <div>
                      <p className="text-[#777777] mb-2">
                        <i className="fa fa-upload" aria-hidden="true"></i>
                        &nbsp; المشاركات
                      </p>
                      <span className="block text-2xl text-center">
                        {kid['publications']}
                      </span>
                    </div>
                  </div>
                  <div className="hidden sm:block text-center">
                    <p className="text-[#777777] mb-2">
                      <i className="fa fa-eye" aria-hidden="true"></i>
                      &nbsp; الأكثر مشاهدة
                    </p>

                    {kid.mostWatched[0] ? (
                      <p className="text-center"> {kid.mostWatched[0].title}</p>
                    ) : (
                      <span className="block text-center text-2xl">-</span>
                    )}
                  </div>
                </div>
                <div className="flex-1 mt-2 flex ">
                  <Link
                    className="text-[#337ab7] mt-auto font-bold text-lg border border-[#337ab7] rounded w-full block text-center py-1 px-2"
                    to={`/report/${kid.id}`}
                  >
                    تقرير كامل
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
