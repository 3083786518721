import { FC } from 'react'
import { Link } from 'react-router-dom'

import logo from '../../images/logo.svg'

export const AppFormLayout: FC<{ title: string }> = (props) => {
  return (
    <div className="container mx-auto px-[15px]">
      <div className="flex flex-col items-center justify-center">
        <Link className="my-4" to="/">
          <img src={logo} alt="عالم زيدو" />
        </Link>

        <div className="w-full max-w-xl rounded-lg  shadow-md shadow-gray-500 border-2 border-[#34495e]">
          <div className="bg-[#34495e] text-white font-bold text-xl p-4 text-center">
            {props.title}
          </div>
          <div className="bg-white p-4 rounded-b-lg">{props.children}</div>
        </div>
      </div>
    </div>
  )
}
