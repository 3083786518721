import React, { VFC } from 'react'
import { Redirect } from 'react-router'
import { ButtonBlue } from '../../components/buttons/Buttons'
import { AppFormLayout } from '../../components/layout/AppFormLayout'
import { FieldGroup2 } from '../../components/Widgets'

export const ResetPasswordUi: VFC<{
  isNew: string

  password: string
  cfPassword: string
  redirectTo: string
  verification: string[]
  isLoading: boolean
  accepted: boolean

  onChangePassword: React.ChangeEventHandler<HTMLInputElement>
  onChangeCfPassword: React.ChangeEventHandler<HTMLInputElement>
  onChangeAccepted: React.ChangeEventHandler<HTMLInputElement>

  onSubmit: React.FormEventHandler<HTMLFormElement>
}> = (props) => {
  if (props.redirectTo) return <Redirect push to={props.redirectTo} />

  return (
    <AppFormLayout
      title={props.isNew === '0' ? 'تحديث كلمة المرور' : 'تعيين كلمة المرور'}
    >
      <form className=" mx-auto" onSubmit={props.onSubmit}>
        <div className="mb-4">
          <FieldGroup2
            label={props.isNew === '0' ? ' كلمة المرور الجديدة' : 'كلمة المرور'}
            name="password"
            onChange={props.onChangePassword}
            type="password"
            value={props.password}
            required
            error={props.verification}
          />
        </div>
        <div className="mb-4">
          <FieldGroup2
            label={
              props.isNew === '0'
                ? ' تأكيد كلمة المرور الجديدة'
                : 'تأكيد كلمة المرور'
            }
            name="cfPassword"
            onChange={props.onChangeCfPassword}
            type="password"
            value={props.cfPassword}
            required
          />
        </div>
        {props.isNew === '1' && (
          <div className="sm:text-justify border border-[#b1a3a32b] bg-gray-200 p-4 rounded">
            <ul>
              <li className="mb-4">
                <i className="text-green-500 text-xl fa fa-check ml-2"></i>
                <span>
                  هذه المنصة (لوحة تحكم الآباء) موجهة للأشخاص الذين لا تقل
                  أعمارهم عن 16 سنة.
                </span>
              </li>
              <li className="mb-4">
                <i className="text-green-500 text-xl fa fa-check ml-2"></i>
                <span>
                  إتمامك لعملية الاشتراك يعني اطلاعك على شروط وأحكام الموقع وعلى
                  سياسة الخصوصية وموافقتك على كل منهما. إضافة إلى موافقتك على
                  جمع بياناتك و بيانات أطفالك الضرورية للتشغيل المتكامل للمنصة.{' '}
                </span>
                <a
                  href="https://www.zidoworld.com/%D8%B3%D9%8A%D8%A7%D8%B3%D8%A9-%D8%A7%D9%84%D8%AE%D8%B5%D9%88%D8%B5%D9%8A%D8%A9/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-blue-700 underline block"
                >
                  اقرأ المزيد
                </a>
              </li>
              <li className="mb-4">
                <i className="text-green-500 text-xl fa fa-check ml-2"></i>
                <span>
                  موافقتك على شروط الاشتراك في عالم زيدو هي تعهد منك أن لا تقوم
                  بنسخ أي مادة بدون موافقة نصية أو تمرير اسم المستخدم و كلمة
                  المرور الخاصة بك لأي شخص آخر أو تفعيل الاشتراك لأي طفل غير
                  أطفالك الذين هم تحت رعايتك المباشرة{' '}
                </span>
              </li>
              <li className="mb-4">
                <i className="text-green-500 text-xl fa fa-check ml-2"></i>
                <span>
                  نتعهد في عالم زيدو على احترام خصوصية معلومات المستخدم
                </span>
              </li>
              <li className="mb-4">
                <i className="text-green-500 text-xl fa fa-check ml-2"></i>
                <span>
                  نحرص في عالم زيدو على استيفاء المراجعة الشرعية و التربوية
                  للمواد المعروضة. و مع ذلك فما نقدمه جهد لا ندعي كماله{' '}
                </span>
              </li>
              <li className="mb-4">
                <i className="text-green-500 text-xl fa fa-check ml-2"></i>
                <span>
                  جميع المواد المعروضة على موقع عالم زيدو هي ملك لعالم زيدو
                </span>
              </li>
            </ul>

            <label className="">
              <input
                type="checkbox"
                name="accepted"
                className="cursor-pointer ml-2 h-4 w-4"
                onChange={props.onChangeAccepted}
              />
              أوافق على ما ذكر أعلاه
            </label>
          </div>
        )}
        <div className="mt-8 max-w-xs mx-auto">
          <ButtonBlue
            type="submit"
            label="إرسال"
            fullWidth
            disabled={
              (props.isNew === '1' && !props.accepted) ||
              !!props.verification.length ||
              !props.password ||
              !props.cfPassword
            }
          />
        </div>
      </form>
    </AppFormLayout>
  )
}
