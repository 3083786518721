import React, { useCallback, useEffect, useState, VFC } from 'react'
import { useParams } from 'react-router-dom'
import { useProfile } from '../../contexts/ProfileContext'
import { ITicket } from '../../interfaces.ui'
import Helper from '../../utilis/Helpers'
import { TicketUi } from './Ticket.ui'

export const Ticket: VFC = () => {
  const { id } = useParams<{ id: string }>()

  const [isLoading, setIsLoading] = useState(true)
  const [showReply, setShowReply] = useState(false)
  const [ticket, setTicket] = useState<ITicket | null>(null)

  const { profile } = useProfile()
  const profileId = profile?._id || ''

  const toggleReply = useCallback(() => {
    setShowReply((r) => !r)
  }, [])

  const getData = useCallback(() => {
    const url = '/tickets/' + id

    Helper.ApiRequest<ITicket>(url)
      .then(function (tkt) {
        if (typeof tkt !== 'object' || 'error' in tkt) {
          return
        }
        setTicket(tkt)
        setIsLoading(false)

        // update readDate of unread emails in ticket
        let data = JSON.stringify({ readDate: new Date() })
        for (let i = 0; i < tkt.emails.length; i++) {
          let email = tkt.emails[i]
          if (email.from._id !== profileId && !email.readDate) {
            let emailUpdateUrl = '/emails/' + email._id
            Helper.ApiRequest(emailUpdateUrl, { method: 'PUT', body: data })
              .then(function (tkt) {})
              .catch((err) => console.log(err))
          }
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }, [profileId, id])

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <TicketUi
      id={id}
      isLoading={isLoading}
      onRefresh={getData}
      onToggleReply={toggleReply}
      profileId={profileId}
      showReply={showReply}
      ticket={ticket}
    />
  )
}
