import Config from '../config'
import Cookies from 'js-cookie'
import { ILogsBody, IProfile } from '../interface.api'
import { EncryptedLocalStorage } from './EncryptedLocalStorage'

const COOKIE_NAME = 'showColors'
const Helper = {
  ApiRequest: function <T = {}, E = {}>(
    url: string,
    options = {} as RequestInit | null,
    auth = true,
    overideOptions = false,
    token = false as false | string
  ) {
    //TODO test if real url
    url = Config.apiURL + url

    if (!options) options = {}
    if (!options.headers) options.headers = {}
    if (Cookies.get(COOKIE_NAME)) {
      options.credentials = 'include'
    }
    //@ts-ignore
    options.headers['Screen-Resolution'] =
      window.screen.width * window.devicePixelRatio +
      ' X ' +
      window.screen.height * window.devicePixelRatio

    //@ts-ignore
    options.headers['Application-Id'] = 'dashboard'

    if (!overideOptions) {
      if (!options.headers) options.headers = {}

      //@ts-ignore
      if (!options.headers['Accept'])
        //@ts-ignore
        options.headers['Accept'] = 'application/json'

      //@ts-ignore
      if (!options.headers['Content-Type'])
        //@ts-ignore
        options.headers['Content-Type'] = 'application/json'
    }

    if (auth === true) {
      if (!options.headers) options.headers = {}
      //@ts-ignore
      options.headers['Authorization'] = !token
        ? 'Bearer ' + Helper.getJwtToken()
        : 'Bearer ' + token
    }

    var promise = new Promise<T | true | { error: true; message: E }>(function (
      resolve,
      reject
    ) {
      fetch(url, options || {})
        .then(function (response) {
          if (response.status === 401) {
            // 401 Unauthorized access
            Helper.logOut()
            return
          }
          if (response.status === 204) {
            return resolve(true)
          }
          if (response.status === 404) {
            return reject(new Error('المحتوى المطلوب غير متوفر'))
          }
          if (response.status === 500) {
            return reject(new Error('المعذرة. لقد حدث عطل بالخادم'))
          }
          response.json().then(function (data) {
            if (response.status === 400) {
              resolve({ error: true, message: data as E })
            }
            resolve(data as T)
          })
        })
        .catch(function (err) {
          reject(err)
        })
    })
    return promise
  },

  MediaURL: function (resource: string, medium = 'file') {
    var isDbIdRegex = new RegExp('^[0-9a-fA-F]{24}$')
    if (!isDbIdRegex.test(resource)) return resource // not a database ID

    var access_token = Helper.getJwtToken()
    if (access_token) access_token = '?access_token=' + access_token

    var url = Config.apiURL + '/media/' + medium + '/' + resource + access_token
    Helper.log('MediaURL Generated\n', url)
    return url
  },

  setupTrackingScripts: function (consent: boolean) {
    // register trackings if enabled
    let { active, trackingID, isIncluded } = Config.trackings.googleAnalytics
    if (consent && active && !isIncluded) {
      var g_script = document.createElement('script')
      g_script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingID}`
      g_script.async = true
      document.head.appendChild(g_script)

      g_script = document.createElement('script')
      g_script.innerHTML = `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${trackingID}');`
      document.head.appendChild(g_script)
      if (!window.trackingsFunctions) window.trackingsFunctions = []
      window.trackingsFunctions.push(() => {
        window.gtag('config', trackingID, {
          page_path: window.location.pathname,
        })
      })
      Config.trackings.googleAnalytics.isIncluded = true
    }
  },

  logOut: function () {
    Cookies.remove(COOKIE_NAME, { path: '/', domain: getDomain() })
    localStorage.removeItem(Config.localStorage.keyName)
  },

  log: function (...data: any[]) {
    // @ts-ignore
    if (Config.enableLog) console.log(...data)
  },

  date: function (mongooseDate: Date | string) {
    let date = new Date(mongooseDate)
    return date.toLocaleString()
  },
  validatePassword: function (p: string) {
    let errors: string[] = []

    if (p.length < 8) {
      // errors.push("المرجو اختيار رمز سري يتكون من 8 رموز على الأقل");
      errors.push('يرجى اختيار كلمة مرور مكونة من 8 رموز على الأقل')
    }
    if (p.search(/[a-z]/i) < 0) {
      // errors.push("المرجو اختيار رمز سري يضم على الأقل حرفا واحدا");
      errors.push('يجب أن تحتوي كلمة المرور الجديدة على حرف واحد على الأقل')
    }
    if (p.search(/[0-9]/) < 0) {
      // errors.push("المرجو اختيار رمز سري يضم على الأقل رقما واحدا");
      errors.push('يجب أن تحتوي كلمة المرور الجديدة على رقم واحد على الأقل')
    }

    return errors
  },

  accessLog: function (
    category: null,
    resource: null,
    description: string,
    isAction = false
  ) {
    let me = Helper.getStoredProfile()
    if (!me) return

    let body: ILogsBody = {
      isAction: isAction ? true : false,
      parent: me._id,
    }

    if (category) body.category = category
    if (resource) body.resource = resource
    if (description) body.description = description

    let url = '/logs/'
    return Helper.ApiRequest(url, {
      method: 'POST',
      body: JSON.stringify(body),
    })
  },

  async getAndSetEncryptionKey(token: string): Promise<void> {
    const url = '/key'
    const newKey = await Helper.ApiRequest<string>(
      url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      false
    )
    if (typeof newKey !== 'string') return
    EncryptedLocalStorage.setKey(newKey)
  },

  getStoredProfile(): IProfile | undefined {
    const me = EncryptedLocalStorage.get(Config.localStorage.whoamiIndex)
    if (!me) return

    const profile = JSON.parse(me) as IProfile
    return profile
  },
  setStoredProfile: function (profile: IProfile): void {
    EncryptedLocalStorage.set(
      Config.localStorage.whoamiIndex,
      JSON.stringify(profile)
    )
  },
  getJwtToken(): string | null {
    const jwt =
      Cookies.get(COOKIE_NAME) ||
      EncryptedLocalStorage.get(Config.localStorage.tokenIndex)

    return jwt
  },
  setJwtToken: function (token: string): void {
    EncryptedLocalStorage.set(Config.localStorage.tokenIndex, token)
  },
}

export default Helper

// just work for 3 part host max :
// 1 part : http://localhost/
// 2 part : http://domain.com/
// 3 part : http://sub.domain.com/
function getDomain() {
  const full = window.location.host
  //window.location.host is subdomain.domain.com
  const parts = full.split(':')[0].split('.')
  if (parts.length === 1) return parts[0] // for localhost
  if (parts.length === 2) return `${parts[0]}.${parts[1]}` // for http://domain.com

  // We remove the first part
  parts.shift()
  return parts.join('.')
}
