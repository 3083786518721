import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Transition } from '@headlessui/react'
import React, { FC, Fragment } from 'react'

export const AppModal: FC<{
  show: boolean
  onClose: () => void
  title: string
}> = (props) => {
  return (
    <Transition appear show={props.show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={props.onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-xl  align-middle transition-all transform bg-white shadow-lg shadow-black rounded-lg overflow-hidden">
              <div className="bg-[#34495e] text-white font-bold text-xl px-4 py-2 text-center relative flex items-center ">
                <Dialog.Title as="h3" className="flex-1 text-lg text-white">
                  {props.title}
                </Dialog.Title>
                <button
                  className="text-white opacity-80 font-bold text-xl w-12 h-12 rounded-full "
                  onClick={props.onClose}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>

              {props.children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
