import React, { VFC } from 'react'
import { Link } from 'react-router-dom'
import { AppProgressBar } from '../../components/layout/AppProgressBar'
import priceUnderline from '../../images/price-underline.png'
import '../../stylesheets/packages.css'

interface Props {
  isLoading: boolean
  showPromo: boolean
  details:
    | {
        currency: string
        amount: {
          quarterly: number
          yearly: number
        }
      }
    | undefined
}
export const PackagesUi: VFC<Props> = (props) => {
  return (
    <>
      {props.isLoading && <AppProgressBar />}

      <h2 className="page-title">سعر الاشتراك</h2>

      <div>
        <div className="packages-container">
          <div className="package months">
            {props.details && (
              <div className="package-price">
                {props.details.amount['quarterly']}
                <span className="text-[#35a6dd] text-xl font-bold">
                  {props.details.currency}
                </span>
              </div>
            )}
            <div className="package-duration">كل 3 أشهر</div>
            <div>
              <Link to={`/topayment/quarterly`}>
                <button className="action-btn">اشترك الآن</button>
              </Link>
            </div>
          </div>
          {props.details && (
            <div className="package year">
              <div className="package-price">
                <sub>{props.details.amount['quarterly'] * 4}</sub>
                {props.details.amount['yearly']}
                <span className="text-[#35a6dd] text-xl font-bold">
                  {props.details.currency}
                </span>
              </div>
              <div className="package-duration">سنويا</div>
              <div>
                <Link to={`/topayment/yearly`}>
                  <button className="action-btn">اشترك الآن</button>
                </Link>
              </div>
              <p>اشترك الآن لمدة سنة</p>
              <p className="flex flex-col justify-center items-center">
                ووفر{' '}
                {(
                  props.details.amount['quarterly'] * 4 -
                  props.details.amount['yearly']
                ).toFixed(2) +
                  ' ' +
                  props.details.currency}
                <br />
                <img
                  alt="price under line"
                  className="price-underline"
                  src={priceUnderline}
                />
              </p>
            </div>
          )}
        </div>
        {!!props.showPromo && (
          <div className="packages-note">
            <span className="text-green-600 ml-1">
              <i className="fa fa-gift"></i>
            </span>
            عند اشتراكك لأول مرة في أي نوع من أنواع الاشتراكات ستحصل على شهر
            مجانا، وسيتم إضافته تلقائيا إلى حسابكم
          </div>
        )}
      </div>
    </>
  )
}
