import React, { VFC } from 'react'
import { Link } from 'react-router-dom'
import { ButtonBlue } from '../../components/buttons/Buttons'
import { EmailForm } from '../../components/EmailForm'
import { AppProgressBar } from '../../components/layout/AppProgressBar'
import { ITicket } from '../../interfaces.ui'
import Helper from '../../utilis/Helpers'

export const TicketUi: VFC<{
  id: string
  showReply: boolean
  ticket: ITicket | null

  isLoading: boolean
  profileId: string

  onToggleReply: () => void
  onRefresh: () => void
}> = (props) => {
  return (
    <>
      <div className="flex justify-between mb-4 flex-wrap gap-4">
        <div className="flex-1 flex items-center ">
          <Link to="/tickets" className="text-2xl ml-2 text-[#368ed6]">
            <i className="fa fa-chevron-circle-right" aria-hidden="true"></i>
          </Link>
          {props.ticket && (
            <h3 className="text-2xl">{props.ticket?.subject}</h3>
          )}
        </div>
        <div className="whitespace-nowrap">
          <ButtonBlue
            onClick={props.onToggleReply}
            icon={props.showReply ? '' : 'fa fa-share'}
            label={props.showReply ? 'إلغاء' : 'الرد على الرسالة'}
          />
        </div>
      </div>
      <div>
        <TicketDetails
          id={props.id}
          isLoading={props.isLoading}
          onRefresh={props.onRefresh}
          onToggleReply={props.onToggleReply}
          profileId={props.profileId}
          showReply={props.showReply}
          ticket={props.ticket}
        />
      </div>
    </>
  )
}

const TicketDetails: VFC<{
  id: string
  showReply: boolean
  ticket: ITicket | null
  isLoading: boolean
  profileId: string

  onToggleReply: () => void
  onRefresh: () => void
}> = (props) => {
  if (props.isLoading) return <AppProgressBar />

  if (!props.ticket) return null

  const destination =
    props.profileId === props.ticket.contact
      ? props.ticket.account
      : props.ticket.contact
  return (
    <div className="flex flex-col">
      {props.showReply && (
        <div className="mb-6">
          <EmailForm
            toggle={props.onToggleReply}
            isReply={true}
            to={destination}
            id={props.ticket._id}
            subject={props.ticket.subject}
            refresh={props.onRefresh}
          />
        </div>
      )}

      {props.ticket.emails.map((email) => (
        <div
          key={email._id}
          className="mb-5 overflow-hidden rounded-xl border border-[#ddd] "
        >
          <div
            className={`${
              email.from._id === props.profileId
                ? 'bg-[#34495e]'
                : 'bg-[#2bbedd]'
            } text-white font-bold flex items-center p-4 flex-wrap`}
          >
            <div className="text-4xl">
              <i className="fa fa-user-circle-o" aria-hidden="true"></i>
            </div>

            <div className="flex-1 px-2 whitespace-nowrap">
              {email.from.firstName + ' ' + email.from.lastName}{' '}
              <span>{email.from._id === props.profileId ? ' (أنا) ' : ''}</span>
            </div>

            <div className="px-2 whitespace-nowrap" aria-label="تاريخ">
              {Helper.date(email.date)}
            </div>
          </div>
          <div className="p-4">
            <div className="mb-4">
              {email.body.split('\n').map(function (line, k) {
                return <p key={k}>{line}</p>
              })}
            </div>
            {email.attachment && (
              <div>
                <a
                  className="underline"
                  href={Helper.MediaURL(email.attachment)}
                  target="_blank"
                  rel="noreferrer"
                >
                  الملف المرفق
                </a>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
