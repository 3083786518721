import React, { ReactElement, VFC } from 'react'
import Config from '../../config'
import { WordpressPost } from '../../interfaces.ui'
import { WordpressPostCard } from '../../components/WordpressPostCard'
import { FieldGroup2, Select } from '../../components/Widgets'

export const PostsUi: VFC<{
  categories?: { id: number; name: string }[]
  filtredPosts: WordpressPost[]
  currentPage: number
  allPages: number | null
  search: string
  onChangeCategory: React.ChangeEventHandler<HTMLSelectElement>
  onSearch: React.ChangeEventHandler<HTMLInputElement>

  onChangePage: (page: number) => void
}> = (props) => {
  const pages: ReactElement[] = []

  if (props.allPages) {
    for (let j = 1; j <= props.allPages; j++) {
      pages.push(
        <li className="mx-1" key={j}>
          <PaginationButton
            label={`${j}`}
            onClick={() => props.onChangePage(j)}
            disabled={props.currentPage === j}
          />
        </li>
      )
    }
  }

  return (
    <div className="flex flex-col">
      <h3 className="mb-4 text-2xl font-bold">مكتبة زيدو</h3>
      <div className="flex flex-col sm:flex-row justify-between mb-4">
        <label className="flex flex-col sm:flex-row sm:items-center ">
          <div className="whitespace-nowrap font-bold mb-1 ml-2">
            نوع المحتوى
          </div>
          <Select onChange={props.onChangeCategory}>
            <option value={0}> الكل</option>
            {props.categories?.map((cat) => {
              return cat.id !== Config.postConfig.uncategorizedId ? (
                <option key={cat.id} value={cat.id}>
                  {cat.name}
                </option>
              ) : null
            })}
          </Select>
        </label>
        <div className="mt-4 sm:mt-0">
          <FieldGroup2
            type="text"
            value={props.search}
            onChange={props.onSearch}
            placeholder="بحث..."
            addOn={<i className="fa fa-search"></i>}
          />
        </div>
      </div>

      <div className="grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {props.filtredPosts.map((post) => {
          return (
            <WordpressPostCard
              linkToPosts={false}
              key={post.guid.rendered}
              post={post}
            />
          )
        })}
      </div>

      <div className="flex items-center justify-center mt-4">
        <ul className="flex flex-wrap justify-center items-center">
          <li className="ml-1 ">
            <PaginationButton
              label="السابق"
              disabled={props.currentPage <= 1}
              onClick={() => {
                props.onChangePage(props.currentPage - 1)
              }}
            />
          </li>
          {pages}
          <li className="mr-1">
            <PaginationButton
              label="التالي"
              disabled={props.currentPage === props.allPages}
              onClick={() => {
                props.onChangePage(props.currentPage + 1)
              }}
            />
          </li>
        </ul>
      </div>
    </div>
  )
}

const PaginationButton: VFC<{
  label: string
  disabled: boolean
  onClick: () => void
}> = (props) => {
  return (
    <button
      className="text-blue-500 my-1 font-bold disabled:text-gray-500 border shadow-md disabled:shadow-none py-2 px-4 bg-[#efefef] disabled:cursor-not-allowed rounded disabled:bg-transparent"
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.label}
    </button>
  )
}
