import React, { useCallback, useEffect, useMemo, useState, VFC } from 'react'
import { useProfile } from '../../contexts/ProfileContext'
import { IAccountUnreadEmail } from '../../interface.api'
import { ITicket } from '../../interfaces.ui'
import Helper from '../../utilis/Helpers'
import { TicketsUi } from './Tickets.ui'

export const Tickets: VFC = () => {
  const [unread, setUnread] = useState(0)
  const [tickets, setTickets] = useState<ITicket[] | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState('')

  const onSearch: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setSearch(e.currentTarget.value)
    },
    []
  )
  const { profile } = useProfile()
  const profileId = profile?._id || ''

  useEffect(() => {
    async function initUnread() {
      let url = '/accounts/' + profileId + '/unreadEmails/'

      Helper.ApiRequest<IAccountUnreadEmail[]>(url).then(function (msgs) {
        if (typeof msgs !== 'object' || 'error' in msgs) {
          return
        }
        setUnread(msgs.length)
      })
    }

    async function initTickets() {
      let url = '/accounts/' + profileId + '/tickets'

      Helper.ApiRequest<ITicket[]>(url).then(function (tkts) {
        if (typeof tkts !== 'object' || 'error' in tkts) {
          return
        }
        setTickets(tkts)
        setIsLoading(false)
      })
    }

    initTickets()
    initUnread()
  }, [profileId])

  const filteredTicket = useMemo(() => {
    if (!search) return tickets
    if (!tickets) return tickets
    return tickets?.filter((t) => {
      return (
        contains(t.contact, search) ||
        contains(t.subject, search) ||
        t.emails?.some(
          (email) =>
            contains(email.body, search) ||
            contains(email.from?.firstName, search) ||
            contains(email.from?.lastName, search)
        )
      )
    })
  }, [tickets, search])

  return (
    <TicketsUi
      unread={unread}
      isLoading={isLoading}
      profileId={profileId}
      tickets={filteredTicket}
      search={search}
      onSearch={onSearch}
    />
  )
}

function contains(text: string, query: string): boolean {
  if (typeof text !== 'string') return false
  if (typeof query !== 'string') return false
  return text.toLowerCase().indexOf(query.toLowerCase()) >= 0
}
