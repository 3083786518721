import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RadioGroup } from '@headlessui/react'
import moment from 'moment'
import React, { ChangeEventHandler, FormEventHandler, VFC } from 'react'
import swal from 'sweetalert2'
import { ButtonBlue, ButtonUnderlined } from '../components/buttons/Buttons'
import { AppModal } from '../components/modal/modal'
import { FieldGroup2, Select } from '../components/Widgets'
import { IKidResponse } from '../interface.api'
import Helper from '../utilis/Helpers'

// last 20 years
const YEARS_OPTIONS: number[] = []
const thisYear = moment().year()
for (var y = thisYear; y >= thisYear - 20; y--) {
  YEARS_OPTIONS.push(y)
}

interface Props {
  title?: string
  edit: string
  refresh: () => void
  close: () => void
}

interface Errors {
  firstName?: string
  gender?: string
  password?: string
  username?: string
  dateOfBirth?: string
}

interface State {
  showModal: boolean
  id: string
  firstName: string
  middleName: string
  lastName: string
  nickname: string
  real_username: string
  real_password: string
  passwordType: 'password' | 'text'
  month: string | number
  year: string | number
  gender: string
  level: number | string
  errors: Errors
  confirmDob?: boolean
}
export default class UserModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      showModal: true,
      id: this.props.edit,
      firstName: '',
      middleName: '',
      lastName: '',
      nickname: '',
      real_username: '',
      real_password: '',
      passwordType: 'password',
      month: '',
      year: '',
      gender: '',
      level: '',
      errors: {},
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeGender = this.handleChangeGender.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.handleChangeDate = this.handleChangeDate.bind(this)
    this.togglePassword = this.togglePassword.bind(this)
  }

  componentDidMount() {
    if (this.props.edit) this.getKidData(this.props.edit)
  }

  togglePassword = () => {
    this.setState((state) => ({
      passwordType: state.passwordType === 'text' ? 'password' : 'text',
    }))
  }

  validateForm() {
    const {
      real_username,
      real_password,
      month,
      year,
      level,
      firstName,
      gender,
    } = this.state
    let errors: Errors = {}

    if (!real_username) errors['username'] = 'هذا الحقل ضروري'
    if (!real_password && !this.props.edit)
      errors['password'] = 'هذا الحقل ضروري'
    if (!month || !year || !level) errors['dateOfBirth'] = 'هذا الحقل ضروري'
    if (!firstName) errors['firstName'] = 'هذا الحقل ضروري'
    if (!gender) errors['gender'] = 'هذا الحقل ضروري'

    if (Object.keys(errors).length) {
      this.setState({ errors: errors })
      return false
    }

    return true
  }

  getKidData(id: string) {
    let url = '/kids/' + id
    let that = this

    Helper.ApiRequest<IKidResponse>(url).then(function (kid) {
      if (typeof kid !== 'object' || 'error' in kid) {
        return
      }
      that.setState({
        id: id,
        firstName: kid.firstName || '',
        lastName: kid.lastName || '',
        middleName: kid.middleName || '',
        nickname: kid.nickname || '',
        gender: kid.gender,
        real_username: kid.username,
        real_password: '',
        month: moment(kid.dateOfBirth).month() + 1,
        year: moment(kid.dateOfBirth).year(),
        confirmDob: false,
        level: kid.path.level,
      })
    })
  }

  handleChangeGender(val: string) {
    this.setState({ gender: val })
  }

  handleChangeDate: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    var year =
      event.target.name === 'year' ? event.target.value : this.state.year
    var month =
      event.target.name === 'month' ? event.target.value : this.state.month
    if (event.target.name === 'year') {
      this.setState({ year: year })
    } else {
      this.setState({ month: month })
    }

    if (!this.props.edit && year && month) {
      let level
      let age = moment().diff(moment(`${year}-${month}`, 'YYYY-MM'), 'years')
      if (age <= 6) level = 1
      else if (age >= 10) level = 3
      else level = 2

      this.setState({
        level: level,
      })
    }
  }

  handleChange: React.ChangeEventHandler<any> = (event) => {
    switch (event.target.name) {
      case 'firstName':
      case 'middleName':
      case 'lastName':
      case 'nickname':
      case 'real_username':
      case 'real_password':
        let tmp = {} as any
        tmp[event.target.name] = event.target.value
        this.setState(tmp)
        break
      case 'confirmDob':
        let val = {} as any
        val[event.target.name] = event.target.checked
        this.setState(val)
        break
      default:
        break
    }
  }

  editKid() {
    const {
      year,
      month,
      id,
      firstName,
      middleName,
      lastName,
      nickname,
      gender,
      real_username,
      real_password,
    } = this.state

    let that = this
    editExistingKid({
      year,
      month,
      id,
      firstName,
      middleName,
      lastName,
      nickname,
      gender,
      real_username,
      real_password,
    })
      .then(function (res) {
        if (typeof res === 'object' && 'error' in res) {
          return that.setState({ errors: res.message })
        }
        that.props.close()
        that.props.refresh()

        Helper.accessLog(null, null, 'edit child', true)
      })
      .catch(function (err) {
        swal({
          title: '',
          text: err,
          type: 'error',
          confirmButtonText: 'إخفاء',
        })
      })
  }

  handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    this.setState({ errors: {} })

    if (!this.validateForm()) return

    if (this.props.edit) this.editKid()
    else {
      const {
        year,
        month,
        firstName,
        middleName,
        lastName,
        nickname,
        gender,
        real_username,
        real_password,
      } = this.state

      let that = this
      addNewKid({
        year,
        month,
        firstName,
        middleName,
        lastName,
        nickname,
        gender,
        real_username,
        real_password,
      })
        .then(function (res) {
          if (res === null) return
          if (typeof res === 'object' && 'error' in res) {
            return that.setState({ errors: res.message })
          }
          that.props.close()
          that.props.refresh()

          Helper.accessLog(null, null, 'add child', true)
        })
        .catch(function (err) {
          swal({
            title: '',
            text: err,
            type: 'error',
            confirmButtonText: 'إخفاء',
          })
        })
    }
  }

  render() {
    const {
      errors,
      showModal,
      level,
      firstName,
      gender,
      real_username,
      passwordType,
      real_password,
      confirmDob,
      year,
      month,
    } = this.state
    const { edit, close, title } = this.props
    const isEdit = edit ? true : false

    return (
      <AppModal show={showModal} onClose={close} title={title || 'تعديل'}>
        <KidForm
          close={close}
          errors={errors}
          level={level}
          firstName={firstName}
          gender={gender}
          realUsername={real_username}
          passwordType={passwordType}
          realPassword={real_password}
          confirmDob={confirmDob}
          year={year}
          month={month}
          isEdit={isEdit}
          onChangeConfirmDob={this.handleChange}
          onChangeFirstName={this.handleChange}
          onChangeRealPassword={this.handleChange}
          onChangeRealUsername={this.handleChange}
          onChangeGender={this.handleChangeGender}
          onSubmit={this.handleSubmit}
          onChangeMonth={this.handleChangeDate}
          onChangeYear={this.handleChangeDate}
          togglePassword={this.togglePassword}
        />
      </AppModal>
    )
  }
}

const KidForm: VFC<{
  firstName: string

  realUsername: string
  realPassword: string
  passwordType: 'password' | 'text'
  month: string | number
  year: string | number
  gender: string
  level: number | string
  errors: Errors
  confirmDob?: boolean
  isEdit: boolean

  onChangeFirstName: ChangeEventHandler<HTMLInputElement>
  onChangeGender: (val: string) => void
  onChangeRealUsername: ChangeEventHandler<HTMLInputElement>
  onChangeRealPassword: ChangeEventHandler<HTMLInputElement>
  onChangeConfirmDob: ChangeEventHandler<HTMLInputElement>
  onSubmit: FormEventHandler<HTMLFormElement>

  onChangeYear: React.ChangeEventHandler<HTMLSelectElement>
  onChangeMonth: React.ChangeEventHandler<HTMLSelectElement>

  togglePassword: () => void
  close: () => void
}> = (props) => {
  return (
    <form
      onSubmit={props.onSubmit}
      autoComplete="off"
      className="text-right p-4"
    >
      <input
        id="username"
        className="opacity-0 z-[-100] absolute top-[-1000px]"
        type="text"
        name="username"
      />
      <input
        id="password"
        className="opacity-0 z-[-100] absolute top-[-1000px]"
        type="password"
        name="password"
      />
      <div className="flex flex-col">
        <div className="mb-4">
          <FieldGroup2
            name="firstName"
            type="text"
            label="اسم الطفل"
            placeholder="الاسم"
            value={props.firstName}
            onChange={props.onChangeFirstName}
            error={props.errors.firstName}
            required
          />
        </div>
        <div className="mb-4">
          <ToggleGender
            gender={props.gender}
            onChange={props.onChangeGender}
            error={props.errors.gender}
          />
        </div>
        <div className="flex mb-4 flex-wrap gap-4">
          <div className="whitespace-nowrap flex-[40%]">
            <FieldGroup2
              name="real_username"
              type="text"
              label="اسم المستخدم للطفل"
              placeholder="اسم المستخدم للطفل"
              value={props.realUsername}
              onChange={props.onChangeRealUsername}
              error={props.errors.username}
              required
            />
          </div>
          <div className=" flex-[50%]">
            <FieldGroup2
              name="real_password"
              label={'كلمة المرور ' + (props.isEdit ? 'الجديدة' : '')}
              type={props.passwordType}
              value={props.realPassword}
              onChange={props.onChangeRealPassword}
              help={
                props.isEdit
                  ? 'اترك هذا الحقل فارغا للحفاظ على كلمة المرور الحالية'
                  : undefined
              }
              error={props.errors.password}
              required={props.isEdit ? false : true}
              addOn={
                <button
                  type="button"
                  onClick={props.togglePassword}
                  className="inline-block w-8"
                >
                  {props.passwordType === 'text' ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </button>
              }
            />
          </div>
        </div>

        <div className="flex mb-2">
          <label className="ml-4">
            <div className="font-bold mb-1">سنة الميلاد</div>
            <Select
              name="year"
              onChange={props.onChangeYear}
              value={props.year}
            >
              <option value="" disabled>
                السنة
              </option>
              {YEARS_OPTIONS.map(function (val) {
                return (
                  <option key={val} value={val}>
                    {val}
                  </option>
                )
              })}
            </Select>
          </label>

          <label>
            <div className="font-bold mb-1">شهر الميلاد</div>
            <Select
              name="month"
              onChange={props.onChangeMonth}
              value={props.month}
            >
              <option value="" disabled>
                الشهر
              </option>
              <option value="1">يناير</option>
              <option value="2">فبراير</option>
              <option value="3">مارس</option>
              <option value="4">أبريل</option>
              <option value="5">مايو</option>
              <option value="6">يونيو</option>
              <option value="7">يوليو</option>
              <option value="8">أغسطس</option>
              <option value="9">سيبتمبر</option>
              <option value="10">أكتوبر</option>
              <option value="11">نوفمبر</option>
              <option value="12">ديسمبر</option>
            </Select>
          </label>
        </div>
        {props.errors.dateOfBirth && (
          <div className="text-red-500 mt-1">{props.errors.dateOfBirth}</div>
        )}
        <div className="mb-4">
          <LevelLabel isEdit={props.isEdit} level={Number(props.level)} />
        </div>
        {!props.isEdit && props.level && (
          <label className="text-[#bf440b]">
            <input
              type="checkbox"
              name="confirmDob"
              className="cursor-pointer ml-2 h-4 w-4"
              onChange={props.onChangeConfirmDob}
            />
            يحدد المستوى الذي سيضاف إليه طفلك انطلاقًا من تاريخ ميلاده و لا يمكن
            تغييره بعد ذلك. المرجو التحقق من تاريخ الميلاد قبل الحفظ.
          </label>
        )}

        <div className="flex">
          <div className="ml-2">
            <ButtonBlue
              label="حفظ"
              type="submit"
              disabled={!props.isEdit && !props.confirmDob}
            ></ButtonBlue>
          </div>
          <ButtonUnderlined type="button" onClick={props.close} label="إلغاء" />
        </div>
      </div>
    </form>
  )
}

const ToggleGender: VFC<{
  error?: string
  gender: string
  onChange: (val: string) => void
}> = (props) => {
  return (
    <>
      <RadioGroup value={props.gender} onChange={props.onChange}>
        <RadioGroup.Label className="block font-bold mb-1">
          الجنس
        </RadioGroup.Label>
        <div className="inline-flex items-center rounded">
          <RadioGroup.Option value="female">
            {({ checked }) => (
              <div
                className={`cursor-pointer h-8 w-12 rounded-r flex items-center justify-center border ${
                  checked
                    ? 'bg-[#e6e6e6] border-[#adadad] text-[#333] shadow-gray-400 shadow-inner'
                    : ''
                }`}
              >
                بنت
              </div>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value="male">
            {({ checked }) => (
              <div
                className={`cursor-pointer h-8 w-12 rounded-l flex items-center justify-center border ${
                  checked
                    ? 'bg-[#e6e6e6] border-[#adadad] text-[#333] shadow-gray-400 shadow-inner'
                    : ''
                }`}
              >
                ولد
              </div>
            )}
          </RadioGroup.Option>
        </div>
      </RadioGroup>
      {props.error && <div className="text-red-500 mt-1">{props.error}</div>}
    </>
  )
}
const LevelLabel: VFC<{ isEdit: boolean; level: number }> = ({
  isEdit,
  level,
}) => {
  if (!level) return null

  let levelLabel
  switch (level) {
    case 1:
      levelLabel = 'المستوى الأول'
      break
    case 2:
      levelLabel = 'المستوى الثاني'
      break
    case 3:
      levelLabel = 'المستوى الثالث'
      break
    default:
      return null
  }

  const labelColor =
    level === 1
      ? 'text-[#907c28]'
      : level === 2
      ? 'text-[#26bfa1]'
      : 'text-[#f6580f]'

  if (isEdit) {
    return <span className={`font-bold ${labelColor}`}>{levelLabel}</span>
  }
  return (
    <span className="">
      المستوى المناسب لعمر طفلك هو&nbsp;
      <span className={`${labelColor}`}>{levelLabel}</span>
    </span>
  )
}

async function addNewKid({
  year,
  month,
  firstName,
  middleName,
  lastName,
  nickname,
  gender,
  real_username,
  real_password,
}: {
  year: number | string
  month: number | string
  firstName: string
  middleName: string
  lastName: string
  nickname: string
  gender: string
  real_username: string
  real_password: string
}): Promise<
  | null
  | {}
  | {
      error: true
      message: {}
    }
> {
  const me = Helper.getStoredProfile()
  if (!me) return null
  let url = '/kids'
  const dateOfBirth = calculDateOfBirth(year, month)

  return Helper.ApiRequest(url, {
    method: 'POST',
    body: JSON.stringify({
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      nickname: nickname,
      password: real_password,
      dateOfBirth: dateOfBirth,
      gender: gender,
      parent: me._id,
      username: real_username,
    }),
  })
}

interface PutKid {
  firstName: string
  middleName: string
  lastName: string
  nickname: string
  dateOfBirth: string
  gender: string
  username: string
  password?: string
}

function editExistingKid({
  year,
  month,
  id,
  firstName,
  middleName,
  lastName,
  nickname,
  gender,
  real_username,
  real_password,
}: {
  year: number | string
  month: number | string
  id: string
  firstName: string
  middleName: string
  lastName: string
  nickname: string
  gender: string
  real_username: string
  real_password: string
}) {
  let url = '/kids/' + id
  const dateOfBirth = calculDateOfBirth(year, month)
  let body: PutKid = {
    firstName: firstName,
    middleName: middleName,
    lastName: lastName,
    nickname: nickname,
    dateOfBirth: dateOfBirth.toISOString(),
    gender: gender,
    username: real_username,
  }

  if (real_password) body.password = real_password

  // TODO: send only what has changed in this form
  return Helper.ApiRequest(url, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

function calculDateOfBirth(year: number | string, month: number | string) {
  // we use the second day to avoid time zone problem
  return moment(`${year}-${month}-02`, 'YYYY-MM-DD')
}
