import { Popover, Transition } from '@headlessui/react'
import { VFC } from 'react'
import { Link } from 'react-router-dom'

export const AppProfileMenu: VFC<{ label: string; onLogout: () => void }> = (
  props
) => {
  return (
    <Popover className="relative z-50">
      <Popover.Button className={'text-white text-xl'}>
        <span className="ml-2">{props.label}</span>
        <span className="fa fa-caret-down"></span>
      </Popover.Button>

      <Transition
        enter="transition duration-150 ease-out"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0"
      >
        <Popover.Panel className="left-0 absolute bg-white rounded shadow-lg shadow-gray-500 p-4">
          <div className="flex flex-col items-start text-[#337ab7]">
            <Link to="/profile" className="text-right block w-full p-4">
              <span className="fa fa-user"></span>
              <span className="mr-2">الملف الشخصي</span>
            </Link>
            <button
              onClick={props.onLogout}
              className="text-right block w-full p-4"
            >
              <span className="fa fa-sign-out"></span>

              <span className="mr-2 ">تسجيل الخروج</span>
            </button>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
