import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, VFC } from 'react'
import { useProfile } from '../../contexts/ProfileContext'
import logo from '../../images/logo.svg'
import { AppNavLink } from '../AppNavLink'

export const AppSideMenu: VFC<{}> = (props) => {
  let [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className={'text-white text-3xl w-[48px] h-[48px]'}
      >
        <span className="fa fa-bars"></span>
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[1000] overflow-y-auto left-0 top-0"
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="translate-x-[280px]"
              enterTo="translate-x-[0px]"
              leave="ease-out duration-200"
              leaveFrom="translate-x-[0px]"
              leaveTo="translate-x-[280px]"
            >
              <div
                onClick={() => setIsOpen(false)}
                className="absolute top-0 right-0 min-h-screen w-[280px] bg-white shadow-xl shadow-black"
              >
                <SideMenuContent />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

const SideMenuContent: VFC = () => {
  const { logout } = useProfile()
  return (
    <div className="flex flex-col items-center">
      <a className="py-4" href="http://www.zidoworld.com">
        <img src={logo} alt="" />
      </a>
      <div className="border-b-2 border-gray-300 w-full py-3" />
      <AppNavLink to={'/'} icon="fa fa-child" label={'أطفالي'} />
      <AppNavLink to={'/tickets'} icon="fa fa-envelope" label={'الرسائل'} />
      <AppNavLink to={'/posts'} icon="fa fa-book" label={'مكتبة زيدو'} />
      <AppNavLink to={'/profile'} icon="fa fa-user" label={'الملف الشخصي'} />
      <div className="border-b-2 border-gray-300 w-full py-3" />
      <button onClick={logout} className="block w-full p-4">
        <span className="fa fa-sign-out"></span>

        <span className="mr-2 ">تسجيل الخروج</span>
      </button>
    </div>
  )
}
