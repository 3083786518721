import React, { useCallback, VFC } from 'react'
import IdleTimer from 'react-idle-timer'
import { useLocation } from 'react-router-dom'
import { Header } from './components/Header'
import Config from './config'
import { useProfile } from './contexts/ProfileContext'
import Routes from './routes'

const App: VFC = () => {
  const { pathname } = useLocation()
  const { profile, logout } = useProfile()
  const onIdle = useCallback(() => {
    logout()
  }, [logout])

  if (
    !profile &&
    (pathname.indexOf('/forgotpassword') >= 0 ||
      pathname.indexOf('/reset') >= 0)
  )
    return <Routes />
  return (
    <IdleTimer
      element={document}
      onIdle={onIdle}
      timeout={Config.sessionTimeout}
      startOnMount={true}
    >
      <div>
        {!!profile && <Header />}
        <div className="container px-2 mx-auto">
          <Routes />
        </div>
      </div>
    </IdleTimer>
  )
}

export default App
