import { VFC } from 'react'
import { NavLink } from 'react-router-dom'

export const AppNavLink: VFC<{
  to: string
  icon: string
  label: string
}> = (props) => {
  return (
    <NavLink
      exact
      to={props.to}
      className={(isActive) =>
        `whitespace-nowrap  py-2 px-2 font-bold rounded mx-2  ${
          isActive ? 'text-[#358ed7] bg-white font-bold' : 'text-black bg-white'
        }`
      }
    >
      <i className={props.icon} aria-hidden="true"></i>
      <span className="text-sm md:text-sm px-4">{props.label}</span>
    </NavLink>
  )
}
