import React from 'react'
import { RouteComponentProps } from 'react-router'
import swal from 'sweetalert2'
import Config from '../../config'
import Helper from '../../utilis/Helpers'
import { ResetPasswordUi } from './ResetPassword.ui'

type Props = RouteComponentProps<{
  id: string
  token: string
  isNew: string
}> & {}
export default class ResetPassword extends React.Component<
  Props,
  {
    password: string
    cfPassword: string
    redirectTo: string
    verification: string[]
    isLoading: boolean
    accepted: boolean
  }
> {
  constructor(props: Props) {
    super(props)

    this.state = {
      password: '',
      cfPassword: '',
      redirectTo: '',
      verification: [],
      isLoading: true,
      accepted: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    switch (event.target.name) {
      case 'password':
        this.setState({
          password: event.target.value,
          verification: Helper.validatePassword(event.target.value),
        })
        break

      case 'cfPassword':
        this.setState({ cfPassword: event.target.value })
        break

      case 'accepted':
        let val = {} as any
        val[event.target.name] = event.target.checked
        this.setState(val)
        break

      default:
        break
    }
  }

  componentDidMount() {
    Helper.logOut()
    this.setState({ isLoading: false })

    if (this.props.match.params.isNew === '1') {
      swal({
        title: '',
        html: Config.preActivationConfirmationText,
        type: 'info',
        confirmButtonText: 'موافق',
      })
    }
  }

  handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()
    // check the password confirmation if it matches the password
    if (this.state.password !== this.state.cfPassword) {
      swal({
        title: '',
        text: 'المرجو التأكد من أن الحقلين متطابقان',
        type: 'error',
        confirmButtonText: 'إخفاء',
      })
      return
    }

    var that = this

    that.setState({ isLoading: true })
    let url = '/auth/reset'
    Helper.ApiRequest(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: this.props.match.params.id,
        password: this.state.password,
        token: this.props.match.params.token,
      }),
    })
      .then(function (res) {
        swal({
          title: '',
          text: 'لقد تم تحديث كلمة المرور بنجاح',
          type: 'success',
          confirmButtonText: 'إخفاء',
        }).then(function (result) {
          that.setState({ redirectTo: '/login', isLoading: false })
        })
      })
      .catch(function (err) {
        that.setState({ isLoading: false })
        swal({
          title: '',
          text: err.message,
          type: 'error',
          confirmButtonText: 'إخفاء',
        })
      })
  }

  render() {
    return (
      <ResetPasswordUi
        accepted={this.state.accepted}
        cfPassword={this.state.cfPassword}
        isLoading={this.state.isLoading}
        isNew={this.props.match.params.isNew}
        onChangeAccepted={this.handleChange}
        onChangeCfPassword={this.handleChange}
        onChangePassword={this.handleChange}
        onSubmit={this.handleSubmit}
        password={this.state.password}
        redirectTo={this.state.redirectTo}
        verification={this.state.verification}
      />
    )
  }
}
