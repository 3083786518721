import { useMemo, VFC } from 'react'
import { Link } from 'react-router-dom'
import { WordpressPost } from '../interfaces.ui'

export const WordpressPostCard: VFC<{
  post: WordpressPost
  linkToPosts: boolean
}> = (props) => {
  const excerpt = useMemo(() => {
    if (!props.post?.excerpt?.rendered) return ''
    const span = document.createElement('span')
    span.innerHTML = props.post.excerpt.rendered
    const excerpt = span.innerText.substring(0, 100) + '...'
    return excerpt
  }, [props.post.excerpt.rendered])

  return (
    <div className="bg-white overflow-hidden rounded-lg shadow-md shadow-gray-700 flex flex-col">
      <img
        alt=""
        src={props.post._embedded['wp:featuredmedia']['0'].source_url}
        loading="lazy"
      />
      <div className="p-3 flex-1 flex flex-col justify-between">
        <h4 className="font-bold text-lg mb-5">
          <a target="_blank" href={props.post.guid.rendered} rel="noreferrer">
            {props.post.title.rendered}
          </a>
        </h4>

        <div className="flex-1 mb-5">{excerpt}</div>
        {props.linkToPosts ? (
          <Link to="/posts" className="font-bold text-blue-700">
            [ اظهار الكل]
          </Link>
        ) : (
          <a
            href={props.post.guid.rendered}
            className="font-bold text-blue-700"
            target="_blank"
            rel="noreferrer"
          >
            [ المزيد ]
          </a>
        )}
      </div>
    </div>
  )
}
