import moment from 'moment'
import React, { ChangeEventHandler, FormEventHandler, VFC } from 'react'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import { ButtonKidLogin } from '../../components/buttons/ButtonKidLogin'
import { ButtonBlue, ButtonUnderlined } from '../../components/buttons/Buttons'
import { AppProgressBar } from '../../components/layout/AppProgressBar'
import { FieldGroup2 } from '../../components/Widgets'
import UserModal from '../../containers/UserModal'
import fAvatar from '../../images/avatars/f_avatar.png'
import mAvatar from '../../images/avatars/m_avatar.png'
import { IAccountReportsResponse } from '../../interface.api'
import { Publication } from '../../interfaces.ui'
import Helper from '../../utilis/Helpers'

export const ReportUi: VFC<{
  kid?: IAccountReportsResponse
  showModal: boolean
  redirect: boolean
  publication: null | FormData
  pubDescription: string
  isLoading: boolean
  fileName?: string
  onEdit: () => void
  onDelete: () => void
  onCloseForm: () => void
  onRefresh: () => void
  onChangeFile: ChangeEventHandler<HTMLInputElement>
  onChangePublicationDescription: ChangeEventHandler<HTMLInputElement>
  onSubmitPublication: FormEventHandler<HTMLFormElement>
  onKidLogin: () => void
  onDeletePublication: (publication: Publication) => void
}> = (props) => {
  if (props.redirect) return <Redirect push to="/" />

  if (!props.kid || props.isLoading) {
    return <AppProgressBar />
  }

  const kid = props.kid

  return (
    <>
      <div className="flex justify-between mb-4">
        <div className="flex-1 flex items-center">
          <Link to="/" className="text-2xl ml-2 text-[#368ed6]">
            <i className="fa fa-chevron-circle-right" aria-hidden="true"></i>
          </Link>
          <h3 className="text-2xl">تقرير مفصل</h3>
        </div>
        <div>
          <ButtonKidLogin onClick={props.onKidLogin} />
        </div>
      </div>

      <div>
        <div className="border border-[#d4d4d4] bg-white rounded-lg">
          <div className="flex items-center justify-between p-4 flex-wrap">
            <KidPreview data={props.kid} />

            <div className="flex">
              <div className="ml-4">
                <ButtonBlue
                  icon="fa fa-plus"
                  label="تعديل"
                  onClick={props.onEdit}
                />
              </div>

              <ButtonUnderlined label="حذف" onClick={props.onDelete} />
            </div>
          </div>

          <div className="line mb-4">
            <div className="triangle"></div>
          </div>

          <div className="p-4">
            <h4 className="text-[#337ab7] text-xl">
              <i
                className="fa fa-clock-o text-[#777777]"
                aria-hidden="true"
              ></i>
              &nbsp; آخر ظهور &nbsp;
              <small>{moment(kid.lastSeen).format('YYYY/MM/DD H:mm:ss')}</small>
            </h4>
          </div>
          <div className="p-4 flex flex-col md:flex-row justify-between">
            <div className="flex-1">
              <h4 className="text-[#337ab7] text-xl">
                <i
                  className="fa fa-play-circle text-[#777777]"
                  aria-hidden="true"
                ></i>
                &nbsp; القصص المشاهدة
              </h4>
              <div className="mt-4">
                <h5 className="font-bold text-lg mb-2">
                  نسبة المشاهدة من كل القصص
                </h5>
                <div className="mb-4">
                  <ProgressBar value={kid.progress ? kid.progress : 0} />
                </div>
                <table className="w-full">
                  <thead>
                    <tr className="border-[#ddd] border-b-2">
                      <th className="pb-2">القصة</th>
                      <th className="pb-2">الأهداف التربوية</th>
                    </tr>
                  </thead>
                  <tbody>
                    {kid.episodes.map((epi, index, array) => {
                      return (
                        <tr
                          key={epi.title}
                          className={
                            index < array.length - 1
                              ? 'border-[#ddd] border-b'
                              : ''
                          }
                        >
                          <td className="text-[#3c763d] py-2">
                            <Target
                              label={epi.title}
                              done={epi.completed}
                              icon="fa-play-circle"
                            />
                          </td>
                          <td className="py-2">
                            {epi.eduTargets.length
                              ? epi.eduTargets.map((item, itemK) => (
                                  <p key={itemK} className="py-1">
                                    <Target label={item} done={epi.completed} />
                                  </p>
                                ))
                              : '–'}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex-1 mt-4 md:mt-0 md:px-10">
              <h4 className="text-[#337ab7] text-xl">
                <i className="fa fa-eye text-[#777777]" aria-hidden="true"></i>
                &nbsp; الأكثر مشاهدة
              </h4>
              <table className="w-full">
                <thead>
                  <tr className="border-[#ddd] border-b-2">
                    <th>القصة</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  {kid.mostWatched.map((chap, index, array) => {
                    return (
                      <tr
                        key={chap.title}
                        className={
                          index < array.length - 1
                            ? 'border-[#ddd] border-b'
                            : ''
                        }
                      >
                        <td className="text-[#8a6d3b] py-1">
                          <i className="fa fa-star" aria-hidden="true"></i>
                          &nbsp;
                          {chap.title}
                        </td>
                        <td className="py-1">{chap.count}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="p-4">
            <h4 className="text-[#337ab7] text-xl mb-4">
              <i className="fa fa-upload text-[#777777]" aria-hidden="true"></i>
              &nbsp; المشاركات
            </h4>

            <form id="form-upload" onSubmit={props.onSubmitPublication}>
              <div className="">
                <h5 className="font-bold pr-2 mb-2">رفع مشاركة جديدة</h5>
                <div className="mb-4 flex flex-col sm:flex-row sm:items-center items-start">
                  <div className="flex-1 w-full sm:max-w-[50%]">
                    <FieldGroup2
                      name="pubDescription"
                      type="text"
                      placeholder="عنوان المشاركة"
                      value={props.pubDescription}
                      onChange={props.onChangePublicationDescription}
                    />
                  </div>

                  <div className="mt-4 sm:mt-0 sm:mr-4 flex items-center justify">
                    <label
                      tabIndex={0}
                      className="bg-[#d9534f] text-white rounded text-center px-4 py-2 mb-0 ml-4 font-normal inline-flex justify-center items-center cursor-pointer"
                    >
                      <span className="">{props.fileName || 'اختر الملف'}</span>
                      <input
                        name="file"
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={props.onChangeFile}
                      />
                    </label>

                    <ButtonBlue
                      label="رفع"
                      type="submit"
                      disabled={props.publication ? false : true}
                    />
                  </div>
                </div>
              </div>
            </form>

            <h5 className="font-bold pr-2 mb-2">
              {kid.publications.length} مشاركات تم رفعها
            </h5>

            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4 lg:grid-cols-4 lg:gap-8 content-center items-center justify-items-center">
              {kid.publications.map((publ, index) => {
                return (
                  <div key={publ._id} className={`flex flex-col `}>
                    <div className="rounded border overflow-hidden p-1">
                      <img src={Helper.MediaURL(publ['thumbnail'])} alt="" />
                    </div>
                    <div className="flex justify-between mt-1">
                      <button
                        className="text-[#d43f3a] hover:text-[#c9302c]"
                        onClick={() => props.onDeletePublication(publ)}
                      >
                        <i className="fa fa-trash ml-2" aria-hidden="true"></i>
                        حذف
                      </button>
                      <span className="flex-1 text-center text-[#9b9b9b] px-1">
                        {Helper.date(publ['date'])}
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        {props.showModal && (
          <UserModal
            edit={kid.id}
            close={props.onCloseForm}
            refresh={props.onRefresh}
          />
        )}
      </div>
    </>
  )
}

const KidPreview: VFC<{
  data: { gender: string; level: number; displayName: string }
}> = (props) => {
  return (
    <div className="flex">
      <img
        width={64}
        height={64}
        src={props.data.gender === 'female' ? fAvatar : mAvatar}
        className="border-[#d6d6d6] border-4 border-solid rounded-full "
        alt=""
      />
      <div className="flex flex-col justify-center mr-2">
        <div className="text-2xl">{props.data.displayName}</div>
        <div>{` المستوى ${props.data.level}`}</div>
      </div>
    </div>
  )
}

const Target: VFC<{ done?: boolean; icon?: string; label: string }> = (
  props
) => {
  let icon = props.done
    ? props.icon
      ? props.icon
      : 'fa-check-circle'
    : 'fa-circle-thin'
  let green = props.done ? 'text-[#3c763d]' : ''

  return (
    <span className={'px-2 ' + green}>
      <i className={'fa ' + icon} aria-hidden="true"></i>&nbsp;
      {props.label}
    </span>
  )
}

const ProgressBar: VFC<{ value: number }> = (props) => {
  return (
    <div className="w-full bg-gray-200 h-4 rounded overflow-hidden relative">
      <div className="bg-[#337ab7]" style={{ width: props.value + '%' }}>
        <div className="bg-striped h-4"></div>
      </div>
      <div className="font-bold absolute flex items-center justify-center h-full top-0 let-0 w-full text-center text-white">
        {props.value + '%'}
      </div>
    </div>
  )
}
