import React, { ChangeEventHandler } from 'react'
import swal from 'sweetalert2'
import { mutate } from 'swr'
import Helper from '../../utilis/Helpers'
import { ProfileUi } from './Profile.ui'

interface Errors {
  firstName?: string
  cfNewPassword?: string
  currentPassword?: string
  newPassword?: string
}
interface State {
  tab: number
  year: string
  firstName: string
  middleName: string
  lastName: string
  country: string
  region: string
  email: string
  currentPassword: string
  newPassword: string
  cfNewPassword: string
  passwordVerification: string[]
  errors: Errors
  isLoading: boolean
  showModal: boolean
  status: string
  expires: string
}
export default class Profile extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props)

    const me = Helper.getStoredProfile()

    this.state = {
      tab: 1,
      year: new Date(me?.dateOfBirth || 0).getFullYear().toString(),
      firstName: me?.firstName || '',
      middleName: me?.middleName || '',
      lastName: me?.lastName || '',
      country: me?.country || '',
      region: me?.region || '',
      email: me?.email || '',
      currentPassword: '',
      newPassword: '',
      cfNewPassword: '',
      passwordVerification: [],
      errors: {},
      isLoading: true,
      showModal: false,
      status: me?.status || '',
      expires: me?.expires || '',
    }

    this.handleSelect = this.handleSelect.bind(this)

    this.handleSubmitUserData = this.handleSubmitUserData.bind(this)
    this.validateUserDataForm = this.validateUserDataForm.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.closeForm = this.closeForm.bind(this)
    this.unsubscribe = this.unsubscribe.bind(this)
  }

  componentDidMount() {
    this.setState({ isLoading: false })
  }

  handleSelect = (tab: number) => {
    this.setState({ tab: tab })
  }

  closeForm() {
    this.setState({ showModal: false })
  }

  unsubscribe() {
    this.setState({ showModal: true })
  }

  validateUserDataForm() {
    const errors: Errors = {}

    if (!this.state.firstName) errors.firstName = 'هذا الحقل ضروري'

    if (Object.keys(errors).length)
      return this.setState({ errors: errors }, () => {
        return Object.keys(errors).length
      })

    return true
  }

  handleChangePassword: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    // check the password confirmation if it matches the password
    if (this.state.newPassword !== this.state.cfNewPassword) {
      const errors: Errors = {
        cfNewPassword: 'كلمة المرور الجديدة و تأكيدها غير متطابقين',
      }
      this.setState({ errors: errors })
      return
    }

    let that = this
    that.setState({ isLoading: true })
    let me = Helper.getStoredProfile()
    if (!me) return
    let url = '/auth/changePassword'

    Helper.ApiRequest<{}, string>(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: me._id,
        currentPassword: this.state.currentPassword,
        newPassword: this.state.newPassword,
      }),
    })
      .then(function (res) {
        if (typeof res === 'object' && 'error' in res && res.error === true) {
          that.setState({
            errors: { currentPassword: res.message },
            isLoading: false,
          })
        } else {
          that.setState({
            currentPassword: '',
            newPassword: '',
            cfNewPassword: '',
            isLoading: false,
          })
          swal({
            title: '',
            text: 'لقد تم الحفظ بنجاح',
            type: 'success',
            confirmButtonText: 'إخفاء',
          })
        }
      })
      .catch(function (err) {
        that.setState({ isLoading: false })
        swal({
          title: '',
          text: err.message,
          type: 'error',
          confirmButtonText: 'إخفاء',
        })
      })
  }

  handleSubmitUserData: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    // reset errors to remove the error warnings from UI
    this.setState({ errors: {} })

    if (!this.validateUserDataForm()) return

    const me = Helper.getStoredProfile()
    if (!me) return
    let url = '/accounts/' + me._id
    let that = this

    that.setState({ isLoading: true })
    Helper.ApiRequest<{}, string>(url, {
      method: 'PUT',
      body: JSON.stringify({
        firstName: that.state.firstName,
        middleName: that.state.middleName,
        lastName: that.state.lastName,
        country: that.state.country,
        region: that.state.region,
        dateOfBirth: new Date(that.state.year.toString()),
      }),
    })
      .then(function (res) {
        if (typeof res === 'object' && 'error' in res && res.error === true) {
          that.setState({ isLoading: false })
          swal({
            title: '',
            text: res.message,
            type: 'error',
            confirmButtonText: 'إخفاء',
          })
        } else {
          mutate('/me')
          swal({
            title: '',
            text: 'لقد تم الحفظ بنجاح',
            type: 'success',
            confirmButtonText: 'إخفاء',
          }).then(function (result) {
            that.setState({ isLoading: false })
            window.location.href = '/profile'
          })
        }
      })
      .catch(function (err) {
        that.setState({ isLoading: false })
        swal({
          title: '',
          text: err.message,
          type: 'error',
          confirmButtonText: 'إخفاء',
        })
      })
  }
  handleChangeFirstName: ChangeEventHandler<HTMLInputElement> = (event) => {
    this.setState({ errors: {}, firstName: event.target.value })
  }
  handleChangeMiddleName: ChangeEventHandler<HTMLInputElement> = (event) => {
    this.setState({ errors: {}, middleName: event.target.value })
  }
  handleChangeLastName: ChangeEventHandler<HTMLInputElement> = (event) => {
    this.setState({ errors: {}, lastName: event.target.value })
  }
  handleChangeCountry: ChangeEventHandler<HTMLSelectElement> = (event) => {
    this.setState({ errors: {}, country: event.target.value })
  }
  handleChangeRegion: ChangeEventHandler<HTMLInputElement> = (event) => {
    this.setState({ errors: {}, region: event.target.value })
  }
  handleChangeCurrentPassword: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    this.setState({ errors: {}, currentPassword: event.target.value })
  }
  handleChangeCfNewPassword: ChangeEventHandler<HTMLInputElement> = (event) => {
    this.setState({ errors: {}, cfNewPassword: event.target.value })
  }
  handleChangeYear: ChangeEventHandler<HTMLSelectElement> = (event) => {
    this.setState({ errors: {}, year: event.target.value })
  }
  handleChangeNewPassword: ChangeEventHandler<HTMLInputElement> = (event) => {
    this.setState({
      errors: {},
      newPassword: event.target.value,
      passwordVerification: Helper.validatePassword(event.target.value),
    })
  }

  render() {
    return (
      <ProfileUi
        cfNewPassword={this.state.cfNewPassword}
        onChangeCfNewPassword={this.handleChangeCfNewPassword}
        onChangeCountry={this.handleChangeCountry}
        onChangeCurrentPassword={this.handleChangeCurrentPassword}
        country={this.state.country}
        currentPassword={this.state.currentPassword}
        email={this.state.email}
        errors={this.state.errors}
        expires={this.state.expires}
        firstName={this.state.firstName}
        isLoading={this.state.isLoading}
        tab={this.state.tab}
        lastName={this.state.lastName}
        middleName={this.state.middleName}
        newPassword={this.state.newPassword}
        onChangeFirstName={this.handleChangeFirstName}
        onChangeLastName={this.handleChangeLastName}
        onChangeMiddleName={this.handleChangeMiddleName}
        onChangeNewPassword={this.handleChangeNewPassword}
        onChangePassword={this.handleChangePassword}
        onChangeRegion={this.handleChangeRegion}
        onChangeYear={this.handleChangeYear}
        onCloseForm={this.closeForm}
        onSelectTab={this.handleSelect}
        onSubmit={this.handleSubmitUserData}
        onUnsubscribe={this.unsubscribe}
        passwordVerification={this.state.passwordVerification}
        region={this.state.region}
        showModal={this.state.showModal}
        status={this.state.status}
        year={this.state.year}
      />
    )
  }
}
