const Config = {
  apiURL: process.env.REACT_APP_API_URL || '',
  kidsURL: process.env.REACT_APP_KIDS_URL || '',
  enableLog: false,
  maxKids: 1000, // @TODO: get this from BE (may be a config route?)
  registrationLink: 'https://www.zidoworld.com/login/ar/join',
  stripe_pk: process.env.REACT_APP_STRIPE_PK || '',
  commercialWebsite: 'https://www.zidoworld.com',
  sessionTimeout: 3600000, // one hour = 3600 000 ms
  localStorage: {
    keyLength: 64,
    keyName: 'showethVernal',
    tokenIndex: 'hoBrimmer',
    whoamiIndex: 'loopersPlebe',
  },
  trackings: {
    googleAnalytics: {
      active: process.env.REACT_APP_GTM_ACTIVE === 'true',
      trackingID: process.env.REACT_APP_GTM_ID,
      isIncluded: false,
    },
  },
  preActivationConfirmationText:
    '<p class="font-bold mb-3">' +
    'قَبْلَ الْاِشْتِرَاكِ يُرْجَى قِرَاءةُ التَّنْبِيهَاتِ التَّالِيَةِ:' +
    '</p>' +
    "<p class='text-justify'>" +
    'تَمَّ تَطْوِيرُ مَنْهَجِ عَالَمِ زِيدُو، لِيَشْمَلَ 24 وِحْدَةً تَرْبَوِيَّةً وَتَعْلِيمِيَّةً لِكُلِّ مَرْحَلَةٍ عُمْرِيَّةٍ، وَ تَتَكَوَّنُ الوِحْدَةُ الْوَاحِدَةُ مِنْ قِصَّةٍ تُنَاسِبُ الْفِئَةَ الْعُمْرِيَّةَ لِلطِّفْلِ، مَصْحُوبَةً بِأَلْعَابٍ هَادِفَةٍ وَ تَرْفِيهِيَّةٍ مُرْتَبِطَةٍ بِالْقِصَّةِ، وَكِتَابٍ لِلْأَنْشِطَةِ التَّفَاعُلِيَّةِ يُثْرِي مَا دَارَ فِي الْقِصَّةِ مِنْ عَنَاصِرَ تَرْبَوِيّةٍ وَعِلْمِيَّةٍ.<br/>' +
    'عِلْمًا أَنَّنَا مَازِلْنَا نَقُومُ بِإِنْتَاجِ الْوِحْدَاتِ الْمَذْكُورَةِ وَ نَعْمَلُ جَاهِدِينَ لِإتْمَامِهَا وَمُرَاجَعَتِهَا، وَسَيَتِمُّ إِنْ شَاءَ اللّهُ رَفْعُ وِحْدَةٍ جَديدَةٍ لِكُلِّ فِئَةٍ عُمُرِيّةٍ فِي غُضُونِ سِتَّةِ أَسَابِيعَ إِلَى شَهْرَيْنِ بِإِذْنِ اللّهِ.<br/>' +
    'كَمَا نَدْعُوكُمْ أَيْضًا لِزِيَارَةِ مَكْتَبَةِ الْآبَاءِ الَّتِي تَزْخَرُ بِالْعَدِيدِ مِنَ الْمَوَادِّ التَّثْقِيفِيَّةِ وَالْمَقَالَاتِ …' +
    '</p>',
  unsubscribe: {
    reasonsIds: [
      'reason-1',
      'reason-2',
      'reason-3',
      'reason-4',
      'other-reasons',
    ],
    reasons: {
      'reason-1': 'لا أرغب في الاستمرار ',
      'reason-2': 'المنصة لم ترقى الى المستوى المطلوب ',
      'reason-3': 'لايوجد لدي أطفال',
      'reason-4': 'آلية عمل المنصة غير واضحة',
      'other-reasons': 'أسباب أخرى',
    },
  },
  postConfig: {
    uncategorizedId: 1,
    paginationPerPage: 6,
  },
  homeConfig: {
    numberArticle: 3,
  },
}

export default Config
